import { MoopsyMutation } from "@moopsyjs/react";
import { SESSION_TOKEN_KEY, seamlessClient } from "../../../seamless-client";
import { emitToNative } from "../../native-bridge";
import * as LogoutBP from "../../../blueprints/accounts/user/logout";

export async function logout(): Promise<void> {
  const mutation = new MoopsyMutation<LogoutBP.Plug>(
    seamlessClient,
    LogoutBP,
    {
      querySideEffects: [],
    },
    false
  );

  const tokenData = window.localStorage.getItem(SESSION_TOKEN_KEY);

  if (tokenData != null) {
    try {
      const token = JSON.parse(tokenData).token;
      await mutation.call({
        token,
      });
    } catch (e) {
      console.error(e);
    }
  }

  const dbs = await window.indexedDB.databases();

  dbs.forEach((db) => {
    if (db.name) {
      window.indexedDB.deleteDatabase(db.name);
    }
  });

  window.localStorage.clear();
  emitToNative({ event: "logout" });
  window.requestAnimationFrame(() => {
    window.location.reload();
  });
}
