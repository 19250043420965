import React from "react";

export const MeetingCanvasDimensionsCtx = React.createContext({
  width: 0,
  height: 0,
});

export const MeetingCanvas = React.memo(function MeetingCanvas(props: React.PropsWithChildren): JSX.Element {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      setDimensions({ width, height });
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  React.useLayoutEffect(() => {
    if (containerRef.current == null) return;

    const { height, width } = containerRef.current.getBoundingClientRect();
    setDimensions({
      height,
      width
    }); // Re-render now that you know the real height
  }, []);

  return (
    <MeetingCanvasDimensionsCtx.Provider value={dimensions}>
      <div ref={containerRef} style={{ height: "100%", width: "100%" }}>
        {props.children}
      </div>
    </MeetingCanvasDimensionsCtx.Provider>
  );
});