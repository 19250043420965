import { ReactEditor } from "slate-react";
import { Editor, Range, Transforms, after } from "slate";

export function selectNearestWord(editor: ReactEditor) {
  const { selection } = editor;

  if (selection && Range.isCollapsed(selection)) {
    const wordBefore = Editor.before(editor, selection, { unit: 'word' });
    const wordAfter = Editor.after(editor, selection, { unit: 'word' });

    const beforeRange = wordBefore && Editor.range(editor, wordBefore, selection.anchor);
    const afterRange = wordAfter && Editor.range(editor, selection.anchor, wordAfter);

    const beforeText = beforeRange && Editor.string(editor, beforeRange);
    const afterText = afterRange && Editor.string(editor, afterRange);

    const beforeMatch = beforeText && beforeText.match(/\w+$/);
    const afterMatch = afterText && afterText.match(/^\w+/);

    if (beforeMatch) {
      const start = wordBefore!;
      const end = after(editor, start, { unit: 'word' });
      if (end) {
        const range = { anchor: start, focus: end };
        Transforms.select(editor, range);
      }
    } else if (afterMatch) {
      const start = selection.anchor;
      const end = wordAfter!;
      const range = { anchor: start, focus: end };
      Transforms.select(editor, range);
    }
  }
}