import React from "react";
import { BaseElement, Transforms } from "slate";
import { EditorCtx } from "../editor-v2";
import { styled } from "@hiyllo/ux/styled";
import { EditorIsReadOnly } from "../contexts";

const DragHandle = styled("div", {
  position: "absolute",
  bottom: 0,
  right: 0,
  width: 12.5,
  height: 12.5,
  cursor: "nwse-resize",
  // background: "rgba(0, 0, 0, 0.5)",
});

export const HiylloImageElement = React.memo(function HiylloImageElement(props: {
  src: string;
  fsId: string;
  width?: number;
  element: BaseElement;
  children: React.ReactNode;
}): JSX.Element {
  const isReadOnly = React.useContext(EditorIsReadOnly);
  const editor = React.useContext(EditorCtx);
  const [size, setSize] = React.useState({ width: props.width ?? 300, height: "auto" });
  const resizingRef = React.useRef(false);
  const startPos = React.useRef({ x: 0, y: 0 });
  const imageRef = React.useRef<HTMLImageElement>(null);
  const widthRef = React.useRef(size.width);

  const onMouseDown = (e: React.MouseEvent) => {
    if (isReadOnly) return;

    // Start resizing
    resizingRef.current = true;
    startPos.current = { x: e.clientX, y: e.clientY };

    // Prevent text selection while resizing
    document.body.style.userSelect = "none";
  };

  const onMouseMove = (e: MouseEvent) => {
    if (!resizingRef.current || !imageRef.current) return;

    // Calculate new width based on mouse movement
    const dx = e.clientX - startPos.current.x;
    const newWidth = Math.max(50, imageRef.current.clientWidth + dx);

    // Set new image size
    setSize({
      width: newWidth,
      height: "auto", // Keep aspect ratio
    });
    widthRef.current = newWidth;

    // Update start position for next movement
    startPos.current = { x: e.clientX, y: e.clientY };
  };

  const onMouseUp = () => {
    // Stop resizing
    resizingRef.current = false;

    // Re-enable text selection
    document.body.style.userSelect = "auto";

    if (editor == null) throw new Error("editor is null");

    // @ts-expect-error ---
    Transforms.setNodes(editor, { width: widthRef.current }, { at: [], match: n => n === props.element });
  };

  // Add mousemove and mouseup listeners to the window so that resizing continues
  // even if the user moves the cursor outside of the image bounds.
  React.useEffect(() => {
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  return (
    <div>
      <div
        contentEditable={false}
        style={{ position: "relative", display: "inline-block", width: size.width, height: size.height }}
      >
        <img
          ref={imageRef}
          src={props.src}
          style={{ width: size.width, height: size.height }}
          alt="resizable"
          draggable={false}
        />
        {props.children}
        <DragHandle onMouseDown={onMouseDown} />
      </div>
    </div>
  );
});