import React from "react";
import { motion, useMotionValue } from "framer-motion";
import { Modal } from "@hiyllo/ux/modal";
import { useStickiesToggle } from "../stickies-provider";
import { styled } from "@hiyllo/ux/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/pro-light-svg-icons";

import * as CreateStickyBP from "../../../blueprints/stickies/create-sticky";
import * as RetrieveStickiesBP from "../../../blueprints/stickies/retrieve-stickies";
import * as DeleteStickyBP from "../../../blueprints/stickies/delete-sticky";
import * as UpdateStickyBP from "../../../blueprints/stickies/update-sticky";
import { seamlessClient } from "../../../seamless-client";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { type StickyType } from "../../../types/stickies/sticky";
import { type UseMoopsyQueryRetValAny } from "@moopsyjs/react";

const Stage = styled("div", {
  height: "100%",
  width: "100%",
  position: "relative",
  overflow: "hidden",
});

const CreateStickyContainer = styled("div", {
  position: "absolute",
  top: 60,
  left: 20,
  width: 200 - 2 * 2,
  height: 200 - 2 * 2,
  border: "2px blue dashed",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  color: "blue",
});

const Sticky = React.memo(function Sticky(props: {
  sticky: StickyType;
  stickiesQuery: UseMoopsyQueryRetValAny;
}): JSX.Element {
  const height = useMotionValue(props.sticky.position.height);
  const width = useMotionValue(props.sticky.position.width);
  const textareaValueRef = React.useRef<string>(props.sticky.content);
  const updateStickyMutation =
    seamlessClient.useMutation<UpdateStickyBP.Plug>(UpdateStickyBP);
  const deleteStickyMutation =
    seamlessClient.useMutation<DeleteStickyBP.Plug>(DeleteStickyBP, {
      querySideEffects: [props.stickiesQuery],
    });

  const saveStickyText = React.useCallback(
    (evt: React.FocusEvent<HTMLTextAreaElement>) => {
      void updateStickyMutation.call({
        uuid: props.sticky.uuid,
        update: {
          content: evt.target.value,
        },
      });
    },
    [props.sticky.uuid, updateStickyMutation],
  );

  const deleteSticky = React.useCallback(() => {
    void deleteStickyMutation.call({
      uuid: props.sticky.uuid,
    });
  }, [deleteStickyMutation, props.sticky.uuid]);

  React.useEffect(() => {
    return () => {
      if (textareaValueRef.current !== props.sticky.content) {
        void updateStickyMutation.call({
          uuid: props.sticky.uuid,
          update: {
            content: textareaValueRef.current,
          },
        });
      }
    };
  }, [props.sticky.content, props.sticky.uuid]);

  return (
    <motion.div
      drag
      dragMomentum={false}
      dragConstraints={{ current: document.body }}
      onDragEnd={(evt, info) => {
        const rect = (evt.target as HTMLDivElement).getBoundingClientRect();
        console.log('>>>>91', rect);
        void updateStickyMutation.call({
          uuid: props.sticky.uuid,
          update: {
            position: {
              x: rect.x,
              y: rect.y,
              width: props.sticky.position.width,
              height: props.sticky.position.height,
            },
          },
        });
      }}
      initial={{
        translateX: props.sticky.position.x,
        translateY: props.sticky.position.y,
      }}
      style={{
        position: "absolute",
        width,
        height,
        background: "white",
        color: "black",
        zIndex: props.sticky.lastModified.valueOf(),
      }}
    >
      <div style={{ height: "100%", width: "100%", position: "relative" }}>
        <textarea
          onChange={(evt) => {
            textareaValueRef.current = evt.target.value;
          }}
          defaultValue={props.sticky.content ?? "New Sticky..."}
          style={{
            width: "calc(100% - 20px)",
            height: "calc(100% - 100px)",
            color: "black",
            border: "none",
            resize: "none",
            padding: 10,
            paddingBottom: 50,
            fontFamily: "inherit",
            fontSize: "inherit",
            background: "transparent",
          }}
          onBlur={saveStickyText}
        />
        <div
          style={{
            position: "absolute",
            bottom: 10,
            left: 10,
            cursor: "pointer",
          }}
          onClick={deleteSticky}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </div>
        <motion.div
          drag
          dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
          dragElastic={0}
          dragMomentum={false}
          onDrag={(evt, info) => {
            console.log('>>>>152', evt);
            evt.preventDefault();
            evt.stopPropagation();
            height.set(Math.max(50, height.get() + info.delta.y));
            width.set(Math.max(50, width.get() + info.delta.x));
          }}
          onDragEnd={(evt) => {
            const target = evt.target as HTMLDivElement;
            const rect = target.parentElement?.getBoundingClientRect();

            if (!rect) {
              return;
            }

            void updateStickyMutation.call({
              uuid: props.sticky.uuid,
              update: {
                position: {
                  x: rect.x,
                  y: rect.y,
                  width: width.get(),
                  height: height.get(),
                },
              },
            });
          }}
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            height: 10,
            width: 10,
            cursor: "nwse-resize",
            zIndex: 10,
          }}
        />
      </div>
    </motion.div>
  );
});

export const StickiesOverlay = React.memo(
  function StickiesOverlay(): JSX.Element {
    const toggle = useStickiesToggle();
    const stickiesQuery =
      seamlessClient.useQuery<RetrieveStickiesBP.Plug>(
        RetrieveStickiesBP,
        null,
      );
    const createStickyMutation =
      seamlessClient.useMutation<CreateStickyBP.Plug>(CreateStickyBP, {
        querySideEffects: [stickiesQuery],
      });

    const onCreateSticky = React.useCallback(() => {
      void createStickyMutation.call(null);
    }, []);

    return (
      <Modal
        onClose={() => {
          toggle(false);
        }}
      >
        <Stage>
          <CreateStickyContainer onClick={onCreateSticky}>
            {createStickyMutation.isLoading ? (
              <LoadingSpinner />
            ) : (
              <FontAwesomeIcon icon={faPlus} />
            )}
          </CreateStickyContainer>
          {stickiesQuery.data?.stickies.map((sticky) => (
            <Sticky
              key={sticky.uuid}
              sticky={sticky}
              stickiesQuery={stickiesQuery}
            />
          ))}
        </Stage>
      </Modal>
    );
  },
);
