import React from "react";
import fuzzysort from "fuzzysort";
import { Input } from "@hiyllo/ux/input";
import { EmojiMartData } from "@emoji-mart/data";
import { styled, styledRN } from "@hiyllo/ux/styled";
import { useDebounce } from "@hiyllo/ux/use-debounce";
import { TouchableWithoutFeedback, View } from "react-native";

import * as ListEmojiBP from "../blueprints/chat/list-custom-emoji";

import { LoadingSpinnerFullView } from "../platform/loading/spinner-loading-full";
import { seamlessClient } from "../seamless-client";
import { UserFacingCustomEmojiType } from "../types/chat/custom-emoji";

const EmojiPickerWindow = styledRN(View, ({ $theme }) => ({
  backgroundColor: $theme.background3,
  height: 400,
  width: 320,
  borderRadius: 15,
  padding: 10,
  display: "flex",
  flexDirection: "column",
  gap: 5,
}));

const EmojiElem = styled("div", ({ $theme }) => ({
  flexGrow: 0,
  height: 30,
  width: 30,
  fontSize: 25,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  userSelect: "none",
  cursor: "pointer",
  borderRadius: 2.5,
  ":hover": {
    backgroundColor: $theme.midground1,
  },
}));

export interface EmojiType {
  element: string | JSX.Element;
  native: string;
  id: string;
  name: string;
  keywords: string[];
}

let cachedEmojiData: UserFacingCustomEmojiType[] = [];

export const EmojiPicker = React.memo(function EmojiPicker(props: {
  onEmojiSelect: (evt: { native: string }) => void;
  onOpen?: () => void;
  onClose?: () => void;
}): JSX.Element {
  const [emojiMartData, setData] = React.useState<EmojiMartData | null>(null);
  const [query, setQuery] = React.useState<string>("");
  const emojiQuery = seamlessClient.useQuery<ListEmojiBP.Plug>(ListEmojiBP, null);

  React.useEffect(() => {
    void import("@emoji-mart/data").then(({ default: data }) => {
      setData(data as EmojiMartData);
    });
  }, []);

  const data = React.useMemo<EmojiType[]>(() => {
    if (emojiMartData == null) return [];

    const emojiMartEmoji = Object.values(emojiMartData.emojis).map((e): EmojiType => ({
      element: e.skins[0].native,
      native: e.skins[0].native,
      id: e.id,
      name: e.name,
      keywords: e.keywords,
    }));

    if (emojiQuery.data?.emoji != null) {
      cachedEmojiData = emojiQuery.data.emoji;
    }

    const customEmojiEmoji = (emojiQuery.data?.emoji ?? cachedEmojiData).map((e): EmojiType => ({
      element: <img src={e.src} style={{ height: '1em', width: '1em' }} />,
      native: `:${e.slug}:`,
      id: e.slug,
      name: e.slug,
      keywords: [],
    }));

    return [...customEmojiEmoji, ...emojiMartEmoji];
  }, [emojiMartData, emojiQuery.data?.emoji]);

  const emojisToDisplay = React.useMemo<EmojiType[]>((): EmojiType[] => {
    if (data != null) {
      if (query.trim().length === 0) {
        return data;
      } else {
        const emojis: ReadonlyArray<EmojiType> = data;
        const results = fuzzysort.go<EmojiType>(query, emojis, {
          keys: ["id", "name", (o: EmojiType) => o.keywords.join()],
        });
        return (results.map((r) => r.obj));
      }
    }
    else {
      return [];
    }
  }, [data, query]);

  const debounce = useDebounce(300, "overwrite");

  const onQueryChanged = React.useCallback(
    (value: string) => {
      debounce.debounce(() => {
        setQuery(value);
      });
    },
    [debounce],
  );

  React.useEffect(() => {
    props.onOpen?.();
    return () => {
      props.onClose?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TouchableWithoutFeedback onPress={(evt) => evt.stopPropagation()}>
      <EmojiPickerWindow>
        {emojisToDisplay == null ? (
          <LoadingSpinnerFullView />
        ) : (
          <>
            <Input
              onChangeValue={onQueryChanged}
              fullWidth
              placeholder="Search emoji(s?)..."
              autoFocus
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                overflowY: "auto",
                gap: 0,
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              {emojisToDisplay.map((e) => (
                <EmojiElem
                  key={e.id}
                  onClick={() =>
                    props.onEmojiSelect({ native: e.native })
                  }
                  className="EmojiPickerEmoji"
                >
                  {e.element}
                </EmojiElem>
              ))}
            </div>
          </>
        )}
      </EmojiPickerWindow>
    </TouchableWithoutFeedback>
  );
});
