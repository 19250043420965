/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
/**
 * [AI] Get the recommended project for the draft task
 */
export type ParamsType = {
  title: string;
  description: string;
};
export type ResponseType = {
  projectUUID: string | null;
};
export const Endpoint = 'tasks/get-draft-task-recommended-project';
export const Method = 'GET';

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 2,
  per: 1500,
};export const paramsSchema = {"description":"[AI] Get the recommended project for the draft task","type":"object","properties":{"title":{"type":"string"},"description":{"type":"string"}},"required":["description","title"],"$schema":"http://json-schema.org/draft-07/schema#"}