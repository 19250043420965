/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { SettingsType } from "../../types/settings/settings-type";

type SettingsSet<K extends keyof SettingsType> = {
  key: K;
  value: SettingsType[K];
};

export type ParamsType = SettingsSet<keyof SettingsType>;
export type ResponseType = void;
export const Endpoint = "settings/set";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 5,
  per: 2000,
};
export const paramsSchema = {"type":"object","properties":{"key":{"type":"string","enum":["email/signature"]},"value":{"type":["null","string"]}},"required":["key","value"],"$schema":"http://json-schema.org/draft-07/schema#"}