import { formatDiff, useDuration } from '@hiyllo/omni-dynamicui';
import React from 'react';

export const MeetingTimer = React.memo(function MeetingTimer(props: {
  startDate: Date;
  endDate?: Date;
}): JSX.Element {
  const durationStr = useDuration(props.startDate);

  if (props.endDate == null) {
    return <div>{durationStr}</div>;
  }

  return (
    <div>
      {durationStr} /{" "}
      <span style={{ color: "#777" }}>
        {formatDiff(props.endDate.valueOf() - props.startDate.valueOf())}
      </span>
    </div>
  );
});