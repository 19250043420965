import type {
  CreateLocalTracksOptions,
  LocalAudioTrack,
  LocalTrack,
  LocalVideoTrack,
} from "livekit-client";
import {
  createLocalAudioTrack,
  createLocalTracks,
  createLocalVideoTrack,
  facingModeFromLocalTrack,
  Track,
  VideoPresets,
} from "livekit-client";
import * as React from "react";
import { log } from "@livekit/components-core";
import { useMediaDevices } from "@livekit/components-react";
import { CircleButton } from "@hiyllo/ux/circle-button";
import {
  faArrowRight,
  faExclamationTriangle,
} from "@fortawesome/pro-light-svg-icons";
import { MediaDeviceSelect } from "./media-device-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@hiyllo/ux/typography";
import { useIsSolo } from "../../../platform/hooks/use-is-solo";
import { Electron } from "../../../platform/electron";
import { Stardate, StardateLogKind, StardateSourceEnum } from "@hiyllo/stardate/main";
import { useSelf, useTenant } from "@hiyllo/omni-continuity/main";

/** @public */
export interface LocalUserChoices {
  username: string;
  videoEnabled: boolean;
  audioEnabled: boolean;
  videoDeviceId: string;
  audioDeviceId: string;
}

const DEFAULT_USER_CHOICES = {
  username: "",
  videoEnabled: true,
  audioEnabled: true,
  videoDeviceId: "default",
  audioDeviceId: "default",
};

/** @public */
export interface PreJoinProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "onSubmit" | "onError"> {
  /** This function is called with the `LocalUserChoices` if validation is passed. */
  onSubmit: (values: LocalUserChoices) => void;
  /**
   * Provide your custom validation function. Only if validation is successful the user choices are past to the onSubmit callback.
   */
  onValidate?: (values: LocalUserChoices) => boolean;
  onError?: (error: Error) => void;
  /** Prefill the input form with initial values. */
  defaults?: Partial<LocalUserChoices>;
  /** Display a debug window for your convenience. */
  debug?: boolean;
  joinLabel?: string;
  micLabel?: string;
  camLabel?: string;
  userLabel?: string;
}

/** @alpha */
export function usePreviewTracks(
  options: CreateLocalTracksOptions,
  onError?: (err: Error) => void,
): LocalTrack[] | undefined {
  const [tracks, setTracks] = React.useState<LocalTrack[]>();

  React.useEffect(() => {
    let trackPromise: Promise<LocalTrack[]> | undefined;
    let needsCleanup = false;
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    if (options.audio || options.video) {
      void createLocalTracks(options)
        .then((tracks) => {
          if (needsCleanup) {
            tracks.forEach((tr) => tr.stop());
          } else {
            setTracks(tracks);
          }
        }).catch(error => {
          console.error(`[HiylloMeet] Failed to create local tracks`, error, JSON.stringify(error));

          if (error.name.includes("NotFoundError")) {
            error = new Error("Your device does not have a camera or microphone connected, or there was an issue accessing them.");
          }

          onError?.(error as Error);
        });
    }

    return () => {
      needsCleanup = true;
      void trackPromise?.then((tracks) =>
        tracks.forEach((track) => {
          track.stop();
        }),
      );
    };
  }, [JSON.stringify(options)]);

  return tracks;
}

/** @public */
export function usePreviewDevice<T extends LocalVideoTrack | LocalAudioTrack>(
  enabled: boolean,
  deviceId: string,
  kind: "videoinput" | "audioinput",
): any {
  const tenant = useTenant();
  const userId = useSelf().userId;
  const stardate = React.useRef(new Stardate(StardateSourceEnum.frontendWeb, "omni", "usePreviewDevice", tenant, userId)).current;
  const [deviceError, setDeviceError] = React.useState<Error | null>(null);
  const [isCreatingTrack, setIsCreatingTrack] = React.useState<boolean>(false);

  const devices = useMediaDevices({ kind });
  const [selectedDevice, setSelectedDevice] = React.useState<
    MediaDeviceInfo | undefined
  >(undefined);

  const [localTrack, setLocalTrack] = React.useState<T>();
  const [localDeviceId, setLocalDeviceId] = React.useState<string>(deviceId);

  React.useEffect(() => {
    setLocalDeviceId(deviceId);
  }, [deviceId]);

  const createTrack = async (
    deviceId: string,
    kind: "videoinput" | "audioinput",
  ): Promise<void> => {
    try {
      const track =
        kind === "videoinput"
          ? await createLocalVideoTrack({
            deviceId,
            resolution: VideoPresets.h720.resolution,
          })
          : await createLocalAudioTrack({ deviceId });

      const newDeviceId = await track.getDeviceId();
      if (newDeviceId && deviceId !== newDeviceId) {
        prevDeviceId.current = newDeviceId;
        setLocalDeviceId(newDeviceId);
      }
      setLocalTrack(track as T);
    } catch (e) {
      if (e instanceof Error) {
        void navigator.mediaDevices.enumerateDevices().then(devices => {
          stardate.log({
            kind: StardateLogKind.error,
            message: "usePreviewDevice.createTrack failed",
            error: e,
            data: {
              deviceId,
              kind,
              devices: devices.map(d => ({ deviceId: d.deviceId, label: d.label, kind: d.kind })),
            }
          });
        });
        setDeviceError(e);
      }
    }
  };

  const switchDevice = async (
    track: LocalVideoTrack | LocalAudioTrack,
    id: string,
  ): Promise<void> => {
    await track.setDeviceId(id);
    prevDeviceId.current = id;
  };

  const prevDeviceId = React.useRef(localDeviceId);

  React.useEffect(() => {
    if (enabled && !localTrack && !deviceError && !isCreatingTrack) {
      log.debug("creating track", kind);
      setIsCreatingTrack(true);
      void createTrack(localDeviceId, kind).finally(() => {
        setIsCreatingTrack(false);
      });
    }
  }, [enabled, localTrack, deviceError, isCreatingTrack]);

  // switch camera device
  React.useEffect(() => {
    if (!localTrack) {
      return;
    }
    if (!enabled) {
      log.debug(`muting ${kind} track`);
      void localTrack.mute().then(() => log.debug(localTrack.mediaStreamTrack));
    } else if (
      selectedDevice?.deviceId &&
      prevDeviceId.current !== selectedDevice?.deviceId
    ) {
      log.debug(
        `switching ${kind} device from`,
        prevDeviceId.current,
        selectedDevice.deviceId,
      );
      void switchDevice(localTrack, selectedDevice.deviceId);
    } else {
      log.debug(`unmuting local ${kind} track`);
      void localTrack.unmute();
    }
  }, [localTrack, selectedDevice, enabled, kind]);

  React.useEffect(() => {
    return () => {
      if (localTrack) {
        log.debug(`stopping local ${kind} track`);
        localTrack.stop();
        void localTrack.mute();
      }
    };
  }, []);

  React.useEffect(() => {
    setSelectedDevice(devices.find((dev) => dev.deviceId === localDeviceId));
  }, [localDeviceId, devices]);

  return {
    selectedDevice,
    localTrack,
    deviceError,
  };
}

/**
 * The PreJoin prefab component is normally presented to the user before he enters a room.
 * This component allows the user to check and select the preferred media device (camera und microphone).
 * On submit the user decisions are returned, which can then be passed on to the LiveKitRoom so that the user enters the room with the correct media devices.
 *
 * @remarks
 * This component is independent from the LiveKitRoom component and don't has to be nested inside it.
 * Because it only access the local media tracks this component is self contained and works without connection to the LiveKit server.
 *
 * @example
 * ```tsx
 * <PreJoin />
 * ```
 * @public
 */
export function PreJoin({
  defaults = {},
  onValidate,
  onSubmit,
  debug,
  joinLabel = "Join Room",
  micLabel = "Microphone",
  camLabel = "Camera",
  userLabel = "Username",
  ...htmlProps
}: PreJoinProps): JSX.Element {
  const [userChoices, setUserChoices] = React.useState(DEFAULT_USER_CHOICES);
  const [username, setUsername] = React.useState(
    defaults.username ?? DEFAULT_USER_CHOICES.username,
  );
  const [videoEnabled, setVideoEnabled] = React.useState<boolean>(
    defaults.videoEnabled ?? DEFAULT_USER_CHOICES.videoEnabled,
  );
  const initialVideoDeviceId =
    defaults.videoDeviceId ?? DEFAULT_USER_CHOICES.videoDeviceId;
  const [videoDeviceId, setVideoDeviceId] =
    React.useState<string>(initialVideoDeviceId);
  const initialAudioDeviceId =
    defaults.audioDeviceId ?? DEFAULT_USER_CHOICES.audioDeviceId;
  const [audioEnabled, setAudioEnabled] = React.useState<boolean>(
    defaults.audioEnabled ?? DEFAULT_USER_CHOICES.audioEnabled,
  );
  const [audioDeviceId, setAudioDeviceId] =
    React.useState<string>(initialAudioDeviceId);

  const [error, setError] = React.useState<Error | null>(null);

  console.log(`[HiylloMeet]`, { initialAudioDeviceId, initialVideoDeviceId });

  const tracks = usePreviewTracks(
    {
      audio: { deviceId: initialAudioDeviceId },
      video: { deviceId: initialVideoDeviceId },
    },
    setError,
  );

  React.useEffect(() => {
    void Electron.callAPI('requestCameraMicrophonePermissions').then((result) => {
      if (!result.microphone) {
        setError(new Error("could not start video source"));
      }
    });
  }, []);

  const videoEl = React.useRef(null);

  const videoTrack = React.useMemo(
    () =>
      tracks?.filter(
        (track) => track.kind === Track.Kind.Video,
      )[0] as LocalVideoTrack,
    [tracks],
  );

  const audioTrack = React.useMemo(
    () =>
      tracks?.filter(
        (track) => track.kind === Track.Kind.Audio,
      )[0] as LocalAudioTrack,
    [tracks],
  );

  React.useEffect(() => {
    if (videoEl.current && videoTrack) {
      void videoTrack.unmute();
      videoTrack.attach(videoEl.current);
    }

    return () => {
      videoTrack?.detach();
      videoTrack?.stop();
      audioTrack?.stop();
    };
  }, [videoTrack]);

  const [isValid, setIsValid] = React.useState<boolean>();

  const handleValidation = React.useCallback(
    (values: LocalUserChoices) => {
      if (typeof onValidate === "function") {
        return onValidate(values);
      }
    },
    [onValidate],
  );

  React.useEffect(() => {
    const newUserChoices = {
      username,
      videoEnabled,
      videoDeviceId,
      audioEnabled,
      audioDeviceId,
    };
    setUserChoices(newUserChoices);
    setIsValid(handleValidation(newUserChoices));
  }, [
    username,
    videoEnabled,
    handleValidation,
    audioEnabled,
    audioDeviceId,
    videoDeviceId,
  ]);

  function handleSubmit(event: React.FormEvent): void {
    event.preventDefault();
    onSubmit(userChoices);
  }

  const isSolo = useIsSolo();

  if (error != null) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 5,
          textAlign: "center",
        }}
      >
        <div style={{ fontSize: 40 }}>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </div>
        <Typography.SubHeader>{error.message}</Typography.SubHeader>
        {error.message.toLowerCase().includes("permission") ? (
          <>
            <div style={{ maxWidth: 450 }}>
              Hiyllo {isSolo ? "Solo" : "Work"} was unable to access your camera
              and/or microphone. Please adjust your permissions then reload the
              page.
            </div>
            <div>
              <a
                href="https://support.google.com/chrome/answer/114662"
                target="_blank"
                rel="noreferrer"
              >
                Click here for instructions to fix permissions on Chrome
              </a>
            </div>
            <div>
              <a
                href="https://support.apple.com/guide/safari/websites-ibrwe2159f50/mac"
                target="_blank"
                rel="noreferrer"
              >
                Click here for instructions to fix permissions on Safari
              </a>
            </div>
            <div style={{ height: 20 }} />
            <div>
              Still having trouble? Email{" "}
              <b>
                <a href="support@hiyllo.io">support@hiyllo.io</a>
              </b>
            </div>
          </>
        ) : null}
        {(Electron.isElectron && error.message.toLowerCase().includes("could not start video source")) ? (
          <>
            <div style={{ maxWidth: 450 }}>
              Hiyllo {isSolo ? "Solo" : "Work"} was unable to access your camera
              and/or microphone. Head to <b>System Settings</b> &gt; <b>Security & Privacy</b> &gt; <b>Camera</b> and make sure Hiyllo {isSolo ? "Solo" : "Work"} is turned on, then do the same for <b>Security & Privacy</b> &gt; <b>Microphone</b>.
            </div>
            <div style={{ height: 10 }} />
            <div>
              Once completed, close and reopen Hiyllo {isSolo ? "Solo" : "Work"} and rejoin the meeting.
            </div>
            <div style={{ height: 20 }} />
            <div>
              Still having trouble? Email{" "}
              <b>
                <a href="support@hiyllo.io">support@hiyllo.io</a>
              </b>
            </div>
          </>
        ) : null}
        {error.message.toLowerCase().includes("inuse") ||
          error.message.toLowerCase().includes("in use") ? (
          <>
            <div style={{ maxWidth: 450 }}>
              Hiyllo {isSolo ? "Solo" : "Work"} was unable to access your camera
              and/or microphone because another app on your computer is already
              using the microphone or camera. Close the other app then reload
              This page.
            </div>
            <div style={{ height: 20 }} />
            <div>
              Still having trouble? Email{" "}
              <b>
                <a href="support@hiyllo.io">support@hiyllo.io</a>
              </b>
            </div>
          </>
        ) : null}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "calc(100% - 120px)",
        alignItems: "center",
        gap: 40,
        paddingLeft: 40,
        paddingRight: 40,
      }}
    >
      <div>
        <video
          ref={videoEl}
          width="1280"
          height="720"
          style={{ width: "100%", objectFit: "contain" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <div>
          <MediaDeviceSelect
            initialSelection={audioDeviceId}
            kind="audioinput"
            onActiveDeviceChange={(id) => setAudioDeviceId(id)}
            onEnabledChange={(enabled) => setAudioEnabled(enabled)}
            enabled={audioEnabled}
            track={audioTrack}
            requestPermissions={true}
          />
        </div>
        <div>
          <MediaDeviceSelect
            initialSelection={videoDeviceId}
            kind="videoinput"
            onActiveDeviceChange={(id) => setVideoDeviceId(id)}
            onEnabledChange={(enabled) => setVideoEnabled(enabled)}
            enabled={videoEnabled}
            track={videoTrack}
            requestPermissions={true}
          />
        </div>
        {/* @ts-expect-error --- */}
        <CircleButton onClick={handleSubmit} icon={faArrowRight} />
      </div>
    </div>
  );
}
