import { Editor, Element, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { SlateElementWithType } from "../types";

export const withCustomEnterBehavior = (editor: ReactEditor): ReactEditor => {
  const { insertBreak } = editor;

  editor.insertBreak = () => {
    const { selection } = editor;

    if (selection) {
      const [node] = Editor.node(editor, selection);

      // Check if the selected node is a void node
      if (Element.isElement(node) && editor.isVoid(node)) {
        // Insert a new paragraph after the void node
        Transforms.insertNodes(editor, {
          // @ts-expect-error ---
          type: 'paragraph',
          children: [{ text: '' }],
        });

        return;
      }
      if (Element.isElement(node) && (node as SlateElementWithType).type === 'list-item') {
        // Insert a new paragraph after the void node
        Transforms.insertNodes(editor, {
          // @ts-expect-error ---
          type: 'paragraph',
          children: [{ text: '' }],
        });

        return;
      }
    }

    insertBreak();
  };

  return editor;
};