import React from "react";
import { onLoginTokenAvailable, seamlessClient } from "../../seamless-client";

import * as CompleteSamlSSOLoginBP from "../../blueprints/accounts/login/complete-hiyllo-auth-login";
import { LoadingPage } from "@hiyllo/ux/standard-loader";
import { useNavigate } from "react-router-dom";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons";

export const SSO = React.memo(function SSO(): JSX.Element {
  const navigate = useNavigate();
  const completeSamlSSOLoginMutation =
    seamlessClient.useMutation<CompleteSamlSSOLoginBP.Plug>(
      CompleteSamlSSOLoginBP,
    );
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    void (async () => {
      console.log("[SSO] Performing SSO Login");

      const usp = new window.URLSearchParams(window.location.search);

      try {
        const { tokenPackage } = await completeSamlSSOLoginMutation.call({
          token: usp.get("token") ?? "",
        });

        console.log("[SSO] Login Completed");

        await onLoginTokenAvailable(tokenPackage);

        console.log("[SSO] Token Saved");

        const returnRedirect = usp.get("then");

        if (returnRedirect && returnRedirect.length > 0 && returnRedirect.startsWith(window.location.origin)) {
          navigate(returnRedirect.slice(window.location.origin.length));
        }
        else {
          throw new Error("Invalid returnRedirect");
        }
      } catch (e: any) {
        setError(e.description ?? e.message);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return (
      <EmptySplash
        icon={faCircleExclamation}
        label="Unable to log you in"
        hint={error}
      />
    );
  }

  return <LoadingPage />;
});
