/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { MailMessageAttachment } from "../../types/mail/message/message";

export type ParamsType = {
  email: {
    to: string[];
    cc: string[];
    bcc: string[];
    subject: string;
    html: string;
    text: string | null;
    inReplyTo: string | null;
    attachments?: MailMessageAttachment[];
  };
  mailboxUUID?: string | null;
  /**
   * Given a messageUUID of a draft, will update the provided draft
   * message with the specified params.email, replacing the draft
   */
  fromDraft?: string | null;
};
export type ResponseType = {
  messageUUID: string;
};
export const Endpoint = "mail/compose-email";
export const Method = "GET";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 1,
  per: 3000,
};
export const paramsSchema = {"type":"object","properties":{"email":{"type":"object","properties":{"to":{"type":"array","items":{"type":"string"}},"cc":{"type":"array","items":{"type":"string"}},"bcc":{"type":"array","items":{"type":"string"}},"subject":{"type":"string"},"html":{"type":"string"},"text":{"type":["null","string"]},"inReplyTo":{"type":["null","string"]},"attachments":{"type":"array","items":{"$ref":"#/definitions/MailMessageAttachment"}}},"required":["bcc","cc","html","inReplyTo","subject","text","to"]},"mailboxUUID":{"type":["null","string"]},"fromDraft":{"description":"Given a messageUUID of a draft, will update the provided draft\nmessage with the specified params.email, replacing the draft","type":["null","string"]}},"required":["email"],"definitions":{"MailMessageAttachment":{"type":"object","properties":{"fsId":{"type":"string"},"filename":{"type":"string"},"size":{"type":"number"}},"required":["filename","fsId","size"]}},"$schema":"http://json-schema.org/draft-07/schema#"}