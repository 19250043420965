import escapeHtml from 'escape-html';
import { Text } from 'slate';

export const serialize = (node: any) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    // @ts-expect-error ---
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    return string;
  }

  // @ts-expect-error ---
  const children = node.children.map(n => serialize(n)).join('');

  switch (node.type) {
    case 'quote':
      return `<blockquote><p>${children}</p></blockquote>`;
    case 'paragraph':
      return `<div>${typeof children === "string" && children.trim().length === 0 ? "<br>" : children}</div>`;
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    default:
      return children;
  }
};