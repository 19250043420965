import React from "react";

import * as RetrieveStuffHomeBP from "../../../blueprints/stuff/retrieve-stuff-home";
import { seamlessClient } from "../../../seamless-client";
import { type StuffDocument } from "../../../types/stuff/document";
import { styled } from "@hiyllo/ux/styled";
import { useNavigateTo } from "@hiyllo/omni-router";
import { Features } from "../../../types/navigation/features";
import { AnimateChangeInHeight } from "@hiyllo/ux/animation";

const DocumentTileContainer = styled("div", ({ $theme }) => ({
  background: $theme.background3,
  height: 160,
  width: 160,
  padding: 10,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 8,
  borderRadius: 8,
  cursor: "pointer",
}));

const DocumentTileLabel = styled("div", ({ $theme }) => ({
  color: $theme.foreground,
  fontSize: 16,
  fontFamily: "hiyllo",
  textAlign: "center",
}));

const DocumentPreviewContainer = styled("div", ({ $theme }) => ({
  background: $theme.midground,
  height: 110,
  width: 85,
  borderRadius: 4,
  padding: 8,
  userSelect: "none",
  overflow: "hidden",
}));

const DocumentTileView = React.memo(function DocumentTileView(props: {
  document: Omit<StuffDocument, "contents">;
}): JSX.Element {
  const navigate = useNavigateTo({
    feature: Features.stuff,
    params: {
      view: "document",
      uuid: props.document.uuid,
    },
  });

  return (
    <DocumentTileContainer onClick={navigate}>
      <DocumentPreviewContainer>
        <div
          dangerouslySetInnerHTML={{
            __html: props.document.contentPreview ?? "<div></div>",
          }}
          style={{
            zoom: "0.25",
          }}
        />
      </DocumentPreviewContainer>
      <DocumentTileLabel>{props.document.name}</DocumentTileLabel>
    </DocumentTileContainer>
  );
});

export const StuffHomeView = React.memo(function StuffHomeView(): JSX.Element {
  const stuffHomeQuery =
    seamlessClient.useQuery<RetrieveStuffHomeBP.Plug>(
      RetrieveStuffHomeBP,
      null,
    );

  return stuffHomeQuery.isLoading || stuffHomeQuery.isError ? (
    <div />
  ) : (
    <div>
      <AnimateChangeInHeight>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: 16,
          }}
        >
          {stuffHomeQuery.data.documents.map((document) => (
            <DocumentTileView document={document} key={document.uuid} />
          ))}
        </div>
      </AnimateChangeInHeight>
    </div>
  );
});
