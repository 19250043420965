/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { ContinuityAccessType } from "../continuity/access";

export enum MailboxTypeEnum {
  personal = "personal",
  shared = "shared",
}

export interface Mailbox {
  uuid: string;
  owner: string;
  address: string;
  access: ContinuityAccessType | null;
  type?: MailboxTypeEnum;
  senderName?: string | null;
}
