import React from "react";
import {
  useTracks as useLiveKitTracks
} from "@livekit/components-react";
import { Track } from "livekit-client";
import { HiylloMeetParticiantIdentityType } from "../../../types/meet";

export function useTracks() {
  const rawTracks = useLiveKitTracks([
    {
      source: Track.Source.Camera,
      withPlaceholder: true,
    },
    {
      source: Track.Source.ScreenShare,
      withPlaceholder: false,
    },
  ]);

  const tracks = React.useMemo(() => {
    return rawTracks.filter(t => {
      if (t.participant.identity === "") return false;

      try {
        const identity: HiylloMeetParticiantIdentityType = JSON.parse(t.participant.identity);
        return identity.type === "user" || identity.spectator !== true;
      }
      catch (e) {
        console.warn("Failed to parse identity", t, JSON.stringify(t.participant.identity));
        return true;
      }
    });
  }, [rawTracks]);

  return tracks;
}