import React from "react";
import {
  type ListTasksSlimTaskType,
  type TaskSizingType,
} from "../../../types/tasks/task-item";
import * as ArchiveTaskBP from "../../../blueprints/tasks/archive-task";
import * as ChangeTaskSprintBP from "../../../blueprints/tasks/change-task-sprint";
import {
  FixedSizingPillContext,
  FixedStatusPillContext,
  HighlightTaskContext,
} from "../kanban-view/task-kanban-view";
import { useUpdateTaskSizing } from "../hooks/use-update-task-sizing";
import { ProjectName } from "../kanban-view/components";
import { SizingPill } from "./sizing-pill";
import { AssigneePill, StatusPill, DueDatePill, useUpdateTaskAssignee, TaskPillsRow, TaskCardContainer } from "@hiyllo/omni-tasks";
import { CondensedDetailsPill } from "../kanban-view/condensed-details-pill";
import {
  ContextMenuContainer,
  ContextMenuItem,
  useContextMenu,
} from "@hiyllo/ux/context-menu";
import {
  faArchive,
  faArrowDownToLine,
  faArrowUpRightFromSquare,
  faArrowUpToLine,
  faBed,
  faLink,
  faRunning,
  faUserPlus,
} from "@fortawesome/pro-light-svg-icons";
import { seamlessClient } from "../../../seamless-client";
import { useMatchesViewCriteria } from "../contexts/view-criteria-context";
import { useDeriveURL } from "@hiyllo/omni-router";
import { Features } from "../../../types/navigation/features";
import { openWindow } from "../../../platform/open-window";
import { useGetTask } from "@hiyllo/omni-tasks";
import { SprintsContext } from "../../tokyo/features/tasks/contexts";
import { SprintStatusEnum } from "../../../types/tasks/sprint";
import { useSelf } from "@hiyllo/omni-continuity/main";
import { Electron } from "../../../platform/electron";
import { motion, useMotionValue } from "framer-motion";
import { MouseState } from "@hiyllo/ux/button";
import { useMouseState } from "@hiyllo/ux/animation";

export const HideTaskProjectLabelsCtx = React.createContext<boolean>(false);

export const TaskCard = React.memo(function TaskCard(props: {
  isDragging?: boolean;
  onClick?: () => void;
  task: Omit<ListTasksSlimTaskType, "assignee"> | ListTasksSlimTaskType;
  showDescription?: string;
  onMoveTaskToTop?: (() => void) | null;
  onMoveTaskToBottom?: (() => void) | null;
}): JSX.Element {
  const { onMouseEnter, onMouseLeave, onMouseDown, onMouseUp, mouseState } = useMouseState();

  const overdue =
    !["done", "wontdo"].includes(props.task.status.id) &&
    (props.task.dueDate?.date.valueOf() ?? Infinity) < Date.now();
  const isHighlighted =
    React.useContext(HighlightTaskContext) === props.task.uuid;
  const fixedSizingPill = React.useContext(FixedSizingPillContext);
  const fixedStatusPill = React.useContext(FixedStatusPillContext);
  const updateTaskSizingMutation = useUpdateTaskSizing();
  const cxMenu = useContextMenu({ positionMode: "fixed" });
  const contextSprints = React.useContext(SprintsContext);
  const activeSprints = React.useMemo(() => contextSprints.filter(s => s.status === SprintStatusEnum.active || s.status === SprintStatusEnum.new), [contextSprints]);
  const updateTaskAssignee = useUpdateTaskAssignee();
  const self = useSelf();

  const assignSelf = React.useCallback(() => {
    void updateTaskAssignee.call({
      taskUUID: props.task.uuid,
      assigneeUserId: self.userId,
    });
    cxMenu.close();
  }, [cxMenu, props.task.uuid, self.userId, updateTaskAssignee]);

  const setSizing = React.useCallback(
    (sizing: TaskSizingType | null) => {
      void updateTaskSizingMutation.call({
        taskUUID: props.task.uuid,
        sizing,
      });
    },
    [props.task.uuid, updateTaskSizingMutation],
  );

  const onContextMenu = React.useCallback((evt: React.MouseEvent) => {
    evt.preventDefault();
    cxMenu.open(
      evt as unknown as React.SyntheticEvent<HTMLButtonElement, MouseEvent>,
    );
  }, []);

  const archiveTaskMutation =
    seamlessClient.useMutation<ArchiveTaskBP.Plug>(ArchiveTaskBP);
  const archiveTask = React.useCallback(() => {
    void archiveTaskMutation.call({
      taskUUID: props.task.uuid,
    });
    cxMenu.close();
  }, [archiveTaskMutation, cxMenu, props.task.uuid]);

  const changeSprintMutation =
    seamlessClient.useMutation<ChangeTaskSprintBP.Plug>(
      ChangeTaskSprintBP,
    );
  const removeFromSprint = React.useCallback(() => {
    void changeSprintMutation.call({
      taskUUID: props.task.uuid,
      newSprintUUID: null,
    });
    cxMenu.close();
  }, [changeSprintMutation, cxMenu, props.task.uuid]);
  const addToSprint = React.useCallback((newSprintUUID: string) => {
    void changeSprintMutation.call({
      taskUUID: props.task.uuid,
      newSprintUUID,
    });
    cxMenu.close();
  }, [changeSprintMutation, cxMenu, props.task.uuid]);

  const visible = useMatchesViewCriteria(props.task);
  const deriveURL = useDeriveURL();

  const openInNewTab = React.useCallback(() => {
    openWindow(
      deriveURL({
        feature: Features.tasks,
        params: {
          view: "task",
          taskUUID: props.task.uuid,
        },
      }),
    );
    cxMenu.close();
  }, [cxMenu, deriveURL, props.task.uuid]);

  const copyLink = React.useCallback(() => {
    void window.navigator.clipboard.writeText(
      deriveURL({
        feature: Features.tasks,
        params: {
          view: "task",
          taskUUID: props.task.uuid,
        },
      }),
    );
    cxMenu.close();
  }, [cxMenu, deriveURL, props.task.uuid]);

  const rotateX = useMotionValue(0);
  const rotateY = useMotionValue(0);


  return (
    <>
      <cxMenu.CXMenuContainer>
        <ContextMenuContainer>
          <ContextMenuItem
            icon={faArchive}
            label="Archive Task"
            onClick={archiveTask}
          />
          <ContextMenuItem
            icon={faLink}
            label="Copy Link"
            onClick={copyLink}
          />
          {props.task.assigneeUserId == null ?
            <ContextMenuItem
              icon={faUserPlus}
              label="Assign to Me"
              onClick={assignSelf}
            />
            : null}
          {props.task.sprintUUID != null ? (
            <ContextMenuItem
              icon={faBed}
              label="Remove from Sprint"
              onClick={removeFromSprint}
            />
          ) : null}
          {props.task.sprintUUID == null && activeSprints.map(sprint => (
            <ContextMenuItem
              icon={faRunning}
              label={`Add to ${sprint.name}`}
              onClick={() => addToSprint(sprint.uuid)}
              key={sprint.uuid}
            />
          ))}
          <ContextMenuItem
            icon={faArrowUpRightFromSquare}
            label={Electron.isElectron ? "Open in New Window" : "Open in New Tab"}
            onClick={openInNewTab}
          />
          {props.onMoveTaskToTop != null ?
            <ContextMenuItem
              icon={faArrowUpToLine}
              label={"Move task to top"}
              onClick={() => {
                props.onMoveTaskToTop?.();
                cxMenu.close();
              }}
            />
            : null}
          {props.onMoveTaskToBottom != null ?
            <ContextMenuItem
              icon={faArrowDownToLine}
              label={"Move task to bottom"}
              onClick={() => {
                props.onMoveTaskToBottom?.();
                cxMenu.close();
              }}
            />
            : null}
        </ContextMenuContainer>
      </cxMenu.CXMenuContainer>
      <div
        onContextMenu={onContextMenu}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
      >
        <motion.div
          style={{ rotateX, rotateY, borderRadius: 5 }}
          animate={{
            boxShadow: mouseState !== MouseState.none ? `0px 0px 15px -10px rgba(0,0,0,0.5)` : "",
            scale:
              mouseState === MouseState.pressed
                ? 0.975
                : mouseState === MouseState.hovered
                  ? 1.025
                  : 1,
          }}
          transition={{
            ease: [0.1, 0.12, 0.1, 0.95],
            duration: 0.1,
          }}
          initial={{ scale: 1 }}
          onMouseMove={(event) => {
            const rect = event.currentTarget.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;
            const centerX = rect.width / 2;
            const centerY = rect.height / 2;
            const deltaX = (x - centerX) / centerX;
            const deltaY = (y - centerY) / centerY;
            console.log('>>>252', deltaX, deltaY);
            rotateY.set(deltaX * 5);
            rotateX.set(deltaY * 5);
          }}
          onMouseLeave={() => {
            rotateX.set(0);
            rotateY.set(0);
          }}
        >
          <TaskCardContainer
            onPress={props.onClick}
            overdue={overdue}
            isHighlighted={isHighlighted}
            translucent={!visible || props.task.deleted?.value === true}
          >
            <TaskPillsRow>
              <CondensedDetailsPill task={props.task} />
              {fixedStatusPill ? (
                <StatusPill status={props.task.status} onChangeStatus={null} />
              ) : null}
              {props.task.dueDate !== null ? (
                <DueDatePill
                  dueDate={props.task.dueDate}
                  onChangeDueDate={null}
                  overdue={overdue}
                />
              ) : null}
              {props.task.assigneeUserId !== null ? (
                <AssigneePill
                  assignee={props.task.assigneeUserId}
                  onChangeAssignee={null}
                  hideLabel={true}
                />
              ) : null}
              {props.task.sizing !== null || fixedSizingPill ? (
                <SizingPill
                  sizing={props.task.sizing}
                  onChangeSizing={setSizing}
                  collapsed={true}
                />
              ) : null}
            </TaskPillsRow>
            {props.task.title}
            {props.showDescription ? (
              <div style={{ fontSize: "0.8em" }}>{props.showDescription}</div>
            ) : null}
            <ProjectName>
              {[props.task.projectName, props.task.sprintName]
                .filter((v) => v != null)
                .join(" / ")}
            </ProjectName>
          </TaskCardContainer>
        </motion.div>
      </div>
    </>
  );
});

export const TaskCardQueryWrapper = React.memo(
  function TaskCardQueryWrapper(props: {
    taskUUID: string;
  }): JSX.Element | null {
    const taskQuery = useGetTask({ uuid: props.taskUUID });

    if (taskQuery.isLoading || taskQuery.isError) {
      return null;
    }

    return (
      <TaskCard
        task={taskQuery.data.task as Omit<ListTasksSlimTaskType, "assignee">}
      />
    );
  },
);
