import { Electron } from "./electron";

export function openWindow(path: string) {
  if (path.startsWith(`https://${Electron.electronHost}`)) {
    path = path.replace(`https://${Electron.electronHost}`, "");
  }

  if (Electron.isElectron) {
    if (path.startsWith("https://") || path.startsWith("http://")) {
      void Electron.callAPI("openExternal", path);
    }
    else {
      const url = new URL(path, `https://${Electron.electronHost}`);
      Electron.callAPI("openWindow", `?${url.search.slice(1)}#${url.pathname}`);
    }
  } else {
    window.open(path, "_blank");
  }
}
