import React from "react";
import { pdfjs, Document, Page } from "react-pdf";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { LoadingSpinnerFullView } from "./loading/spinner-loading-full";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

export const PDFViewer = React.memo(function PDFViewer(props: { src: string }): JSX.Element {
  const [numPages, setNumPages] = React.useState(0);
  const onLoadSuccess = React.useCallback(({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  }, []);

  return (
    <>
      {numPages === 0 ? <LoadingSpinnerFullView /> : null}
      <div style={{ height: "100%", overflowY: "auto", display: numPages === 0 ? "none" : "" }}>
        <Document file={props.src} onLoadSuccess={onLoadSuccess}>
          <div style={{ display: "flex", flexDirection: "column", height: "100%", overflowY: "auto", gap: 10 }}>
            {new Array(numPages).fill(null).map((_, i) => (
              <Page pageNumber={i + 1} height={window.innerHeight - 60} key={i} />
            ))}
          </div>
        </Document>
      </div>
    </>
  );
});