import React from "react";
import { NymblIcon } from "@hiyllo/icons";
import { styled } from "@hiyllo/ux/styled";

const GlowPillContainer = styled("div", ({ $theme }) => ({
  background: $theme.buttonBackground,
  padding: 0,
  borderRadius: 5,
  cursor: "pointer",
  userSelect: "none",
}));

const GlowPillInner = styled("div", ({ $theme }) => ({
  padding: 7.5,
  fontSize: 12.5,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 7.5
}));

export const GradientText = styled("div", ({ $theme }) => ({
  color: "white"
}));

export const NymblPill = React.memo(function GlowPill(
  props: React.PropsWithChildren<{ onClick?: () => void }>,
): JSX.Element {
  return (
    <GlowPillContainer onClick={props.onClick}>
      <GlowPillInner>
        <NymblIcon width={15} color="white" />
        <GradientText>{props.children}</GradientText>
      </GlowPillInner>
    </GlowPillContainer>
  );
});
