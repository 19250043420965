import React from "react";
import { StartedContext, useMeetingEvent } from "./meeting-event-provider";
import { styled } from "@hiyllo/ux/styled";
import { MeetingTimer } from "../ui/meeting-timer";

const ExternalBackgroundView = styled("div", ({ $theme }) => ({
  background: $theme.background1,
  color: $theme.foreground,
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const MeetingTimerWrapper = React.memo(
  function MeetingTimerWrapper(): JSX.Element | null {
    const startDate = React.useContext(StartedContext);
    const event = useMeetingEvent();

    if (event != null) {
      return (
        <MeetingTimer
          startDate={event.timing._computed.start}
          endDate={event.timing._computed.end}
        />
      );
    }

    return <MeetingTimer startDate={startDate ?? new Date()} />;
  },
);

export const WaitingRoom = React.memo(function WaitingRoom(props: {
  started?: Date;
}): JSX.Element {
  return (
    <StartedContext.Provider value={props.started ?? new Date(0)}>
      <ExternalBackgroundView>
        <img src="/ufplogo.png" style={{ height: 50, width: "auto" }} />
        <div style={{ height: 20 }} />
        <MeetingTimerWrapper />
      </ExternalBackgroundView>
    </StartedContext.Provider>
  );
});