import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownload } from "@fortawesome/pro-light-svg-icons";
import { useIsSolo } from "../hooks/use-is-solo";
import { Electron } from "../electron";

const Container = styled("div", ({ $theme }) => ({
  position: "absolute",
  bottom: 20,
  right: 20,
  height: 60,
  backgroundColor: "#1976d2",
  display: "flex",
  flexDirection: "row",
  gap: 10,
  alignItems: "center",
  paddingLeft: 15,
  paddingRight: 15,
  borderRadius: 5,
  color: $theme.foreground,
  zIndex: 1999,
  cursor: "pointer",
  fontFamily: "hiyllo"
}));

export const UpdateBanner = React.memo(function UpdateBanner(): JSX.Element {
  const isSolo = useIsSolo();

  return (
    <Container onClick={() => {
      if (Electron.isElectron) {
        void Electron.callAPI("quitAndInstall");
      }
      else {
        window.location.reload();
      }
    }}>
      <FontAwesomeIcon icon={faCloudDownload} />
      <div>
        <div
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: 14,
          }}
        >
          Update Available
        </div>
        <div
          style={{
            color: "white",
            fontSize: 12,
          }}
        >
          Click here to {Electron.isElectron ? "restart" : "reload"} Hiyllo {isSolo ? "Solo" : "Work"}
        </div>
      </div>
    </Container>
  );
});
