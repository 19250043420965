import { styled } from "@hiyllo/ux/styled";
import { useHourHeight } from "../hooks/use-hour-height";
import React from "react";
import { DayWidthContext } from "../contexts";
import { HOURS } from "../consts";

const DayColumnHourSlot = styled<"div", { hour: number; height: number }>(
  "div",
  ({ $theme, hour, height }) => ({
    height: hour === 0 ? height : height - 1,
    borderTopWidth: hour === 0 ? 0 : 1,
    borderTopStyle: "solid",
    borderTopColor: $theme.midground,
  }),
);

const DayColumnContainer = styled<"div", { day: number; dayWidth: number }>(
  "div",
  ({ $theme, day, dayWidth }) => ({
    borderLeftWidth: day !== 1 ? 0.5 : 0,
    borderRightWidth: day !== 0 ? 0.5 : 0,
    width: dayWidth - 1,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    borderColor: $theme.midground,
    borderStyle: "solid",
  }),
);
export const DayColumn = React.memo(function DayColumn(props: {
  day: number;
}): JSX.Element {
  const height = useHourHeight();
  const dayWidth = React.useContext(DayWidthContext);

  return (
    <DayColumnContainer day={props.day} dayWidth={dayWidth} className={`DayColumn${props.day}`}>
      {HOURS.map((hour) => (
        <DayColumnHourSlot height={height} hour={hour} key={hour} className={`DayColumn${props.day}-Tile-${hour}`} />
      ))}
    </DayColumnContainer>
  );
});
