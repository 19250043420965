import { SettingsType } from "../types/settings/settings-type";
import * as GetSettingBP from '../blueprints/settings/get';
import * as SetSettingBP from '../blueprints/settings/set';
import { seamlessClient } from "../seamless-client";
import { UseMoopsyMutationRetVal, UseMoopsyQueryRetVal, UseMoopsyMutationOptionsType } from "@moopsyjs/react";

export function useSettingQuery<K extends keyof SettingsType>(key: K): UseMoopsyQueryRetVal<Omit<GetSettingBP.Plug, "response"> & { response: SettingsType[K] }> {
  return seamlessClient.useQuery<GetSettingBP.Plug>(GetSettingBP, { key });
}

export function useSettingMutation<K extends keyof SettingsType>(key: K, options?: UseMoopsyMutationOptionsType): UseMoopsyMutationRetVal<Omit<SetSettingBP.Plug, "params"> & { params: { key: K, value: SettingsType[K] } }> {
  return seamlessClient.useMutation<SetSettingBP.Plug>(SetSettingBP, options) as unknown as UseMoopsyMutationRetVal<Omit<SetSettingBP.Plug, "params"> & { params: { key: K, value: SettingsType[K] } }>;
}