import React from "react";
import { useSubmitForm } from "../hooks/use-submit-form";
import { styled } from "@hiyllo/ux/styled";
import {
  useRetrieveFormForSubmission,
  type RetrieveFormForSubmissionBP,
} from "../hooks/use-retrieve-form-for-submission";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import {
  type HiylloFormResponseValue,
  type HiylloFormResponseFields,
  type HiylloFormTextResponseValue,
} from "../../../types/forms/response";
import {
  HiylloFormFormat,
  type HiylloFormField,
} from "../../../types/forms/structure";
import { Input } from "@hiyllo/ux/input";
import { Button } from "@hiyllo/ux/button";
import { type MoopsyError } from "@moopsyjs/core";
import { useAnimate } from "framer-motion";
import { ErrorText } from "../../../ux/error";
import { SearchResultKeyboardHint } from "@hiyllo/omni-search/main/src/ui/overlay/components/keyboard-hint";
import { getRootURL } from "../../../platform/environment/get-root-url";
import { useConfig } from "../../../platform/config/config-context";
import { useParams } from "react-router-dom";
import { FileInputV3 } from "../../../ux/alpha/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-light-svg-icons";

const MultilineShiftEnterHint = React.memo(
  function MultilineShiftEnterHint(): JSX.Element {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2.5,
          marginTop: 10,
          fontSize: 12,
          opacity: 0.75,
        }}
      >
        <SearchResultKeyboardHint value="Shift + Enter" /> for new line
      </div>
    );
  },
);
const OuterContainer = styled("div", {
  width: "100%",
  height: "100%",
  overflow: "auto",
  fontFamily: '"hiyllo", sans-serif',
});

const InnerContainer = styled<"div", { isUnison?: boolean }>(
  "div",
  ({ isUnison }) => ({
    padding: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: isUnison ? 0 : 50,
    height: isUnison ? "calc(100% - 30px)" : "calc(100% - 80px)",
  }),
);

const Logo = styled("img", {
  height: 40,
  width: "auto",
});

const LogoTypography = styled("div", {
  height: "40px",
  lineHeight: "40px",
  fontSize: 20,
});

const Content = styled("div", {
  height: 0,
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  overflowX: "hidden",
});

const Header = styled("div", {
  fontSize: 32,
  marginBottom: 5,
});

const FieldHeader = styled("div", {
  fontSize: 20,
  marginBottom: 10,
});

const FieldDescription = styled("div", {
  fontSize: 16,
  marginBottom: 10,
});

const OptionItem = styled<"div", { selected: boolean }>(
  "div",
  ({ selected, $theme }) => ({
    background: selected ? $theme.midground : $theme.background3,
    padding: 15,
    borderRadius: 10,
    color: $theme.foreground,
    cursor: "pointer",
  }),
);

export function isTextFormResponse(
  value: HiylloFormResponseValue,
): value is HiylloFormTextResponseValue {
  return value.type === "text";
}

export function getTextFromFormResponseValue(
  value?: HiylloFormResponseValue,
): string {
  if (value == null) {
    return "";
  }

  if (!isTextFormResponse(value)) {
    throw new Error("Corrupted");
  }

  return value.value;
}

const Field = React.memo(function Field(props: {
  field: HiylloFormField;
  value?: HiylloFormResponseValue;
  onSetValue: (value: HiylloFormResponseValue) => void;
}) {
  if (props.field.structure.type === "select") {
    const selected = getTextFromFormResponseValue(props.value);

    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        {props.field.structure.options.map((option) => (
          <OptionItem
            onClick={() => props.onSetValue({ type: "text", value: option })}
            selected={selected === option}
            key={option}
          >
            {selected === option ? (
              <>
                <FontAwesomeIcon icon={faCheck} />
                &nbsp;
              </>
            ) : null}
            {option}
          </OptionItem>
        ))}
      </div>
    );
  }

  if (props.field.structure.type === "file-upload") {
    return (
      <FileInputV3
        onFsId={(fileId) => {
          if (fileId != null) {
            props.onSetValue({ type: "file", fileId });
          }
        }}
      />
    );
  }

  const value = getTextFromFormResponseValue(props.value);

  return (
    <>
      <Input
        value={value}
        onChangeValue={(value) => props.onSetValue({ type: "text", value })}
        fullWidth
        autoFocus
        multiline={props.field.structure.multiline}
        key={props.field.uuid}
        inputStyle={props.field.structure.multiline ? { minHeight: "3em" } : {}}
      />
      {props.field.structure.multiline ? <MultilineShiftEnterHint /> : null}
    </>
  );
});

const LoadedFormSequential = React.memo(function LoadedFormSequential({
  form,
  formUUID,
}: {
  form: RetrieveFormForSubmissionBP.ResponseType;
  formUUID: string;
}): JSX.Element {
  const submitFormMutation = useSubmitForm();
  const [success, setSuccess] = React.useState(false);
  const fields = React.useRef<HiylloFormResponseFields>({});
  const [currentField, setCurrentField] =
    React.useState<HiylloFormField | null>(form.structure.fields[0] ?? null);
  const [scope, animate] = useAnimate();
  const [value, setValue] = React.useState<HiylloFormResponseValue>();
  const submittingRef = React.useRef<boolean>(false);

  const onNextField = React.useCallback(async () => {
    if (
      currentField == null ||
      submitFormMutation.isLoading ||
      submittingRef.current ||
      !value
    ) {
      return;
    }

    fields.current = {
      ...fields.current,
      [currentField.uuid]: value,
    };

    const nextField = form.structure.fields.find(
      (field) => !Object.keys(fields.current).includes(field.uuid),
    );

    await animate(
      scope.current,
      { x: "-100%", opacity: 0.5 },
      { duration: 0.15, ease: "easeOut" },
    );

    setValue(undefined);

    if (nextField != null) {
      setCurrentField(nextField);
    } else {
      setCurrentField(null);
      if (submittingRef.current) return;
      submittingRef.current = true;
      void submitFormMutation
        .call({
          uuid: formUUID,
          fields: fields.current,
          respondentEmail: null,
        })
        .then(() => {
          setSuccess(true);
        })
        .catch((err) => {
          alert((err as MoopsyError).message);
        });
    }

    await animate(scope.current, { x: "100%", opacity: 0.25 }, { duration: 0 });
    await animate(
      scope.current,
      { x: "0%", opacity: 1 },
      { duration: 0.15, ease: "easeInOut" },
    );
  }, [
    animate,
    currentField,
    form.structure.fields,
    formUUID,
    scope,
    submitFormMutation,
    value,
  ]);

  React.useEffect(() => {
    const onKeyDown = (evt: KeyboardEvent): void => {
      if (evt.key === "Enter" && !evt.shiftKey) {
        void onNextField();
      }
    };

    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [onNextField]);

  return (
    <Content _ref={scope}>
      {success ? (
        <>
          <Header>Thank you!</Header>
          <FieldHeader>Your submission has been recorded</FieldHeader>
        </>
      ) : currentField == null ? (
        <>
          <Header>{form.name}</Header>
          <LoadingSpinner />
        </>
      ) : (
        <>
          <Header>{form.name}</Header>
          <FieldHeader>{currentField.structure.label}</FieldHeader>
          <FieldDescription>
            {currentField.structure.description}
          </FieldDescription>
          <Field field={currentField} value={value} onSetValue={setValue} />
          <div style={{ marginTop: 15, display: "flex" }}>
            <Button label="Next (or Enter)" onClick={onNextField} />
          </div>
        </>
      )}
    </Content>
  );
});

export function validateFieldResponse(
  response: HiylloFormResponseValue,
): boolean {
  if (response.type === "text") {
    return "value" in response && response.value.length > 0;
  }
  return true;
}

const LoadedFormUnison = React.memo(function LoadedFormUnison({
  form,
  formUUID,
}: {
  form: RetrieveFormForSubmissionBP.ResponseType;
  formUUID: string;
}): JSX.Element {
  const submitFormMutation = useSubmitForm();
  const [success, setSuccess] = React.useState(false);
  const [fields, setFields] = React.useState<HiylloFormResponseFields>({});
  const submittingRef = React.useRef<boolean>(false);
  const [showErrorForFields, setShowErrorForFields] = React.useState<string[]>(
    [],
  );

  const onSubmit = React.useCallback(async () => {
    if (submitFormMutation.isLoading || submittingRef.current) {
      return;
    }

    for (const field of form.structure.fields) {
      if (field.structure.required) {
        if (
          !(field.uuid in fields) ||
          !validateFieldResponse(fields[field.uuid])
        ) {
          return setShowErrorForFields([field.uuid]);
        }
      }
    }

    void submitFormMutation
      .call({
        uuid: formUUID,
        fields,
        respondentEmail: null,
      })
      .then(() => {
        setSuccess(true);
      })
      .catch((err) => {
        alert((err as MoopsyError).message);
      });
  }, [fields, form.structure.fields, formUUID, submitFormMutation]);

  const setValue = React.useCallback(
    (key: string, value: HiylloFormResponseValue) => {
      setFields((field) => ({
        ...field,
        [key]: value,
      }));
    },
    [],
  );

  return (
    <Content>
      <div style={{ height: 15 }} />
      <div style={{ height: "100%", overflowY: "auto" }}>
        {success ? (
          <>
            <Header>Thank you!</Header>
            <FieldHeader>Your submission has been recorded</FieldHeader>
          </>
        ) : (
          <>
            <Header>{form.name}</Header>
            <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
              {form.structure.fields.map((currentField, fieldIndex) => (
                <div key={currentField.uuid}>
                  <FieldHeader>{currentField.structure.label}</FieldHeader>
                  <FieldDescription>
                    {currentField.structure.description}
                  </FieldDescription>
                  {showErrorForFields.includes(currentField.uuid) ? (
                    <div style={{ paddingBottom: 10 }}>
                      <ErrorText message="Value Required" />
                    </div>
                  ) : null}
                  <Field
                    field={currentField}
                    value={fields[currentField.uuid]}
                    onSetValue={(v: HiylloFormResponseValue) =>
                      setValue(currentField.uuid, v)
                    }
                  />
                </div>
              ))}
            </div>
            <div style={{ marginTop: 15, display: "flex" }}>
              <Button label="Submit" onClick={onSubmit} />
            </div>
            <div style={{ height: 30 }} />
          </>
        )}
      </div>
    </Content>
  );
});

export const SubmitForm = React.memo(function SubmitForm(): JSX.Element {
  const { uuid } = useParams<{ uuid: string }>();
  const config = useConfig();
  const hasUFP = config.branding?.logo?.fileId != null;

  const formDetailsQuery = useRetrieveFormForSubmission({
    uuid: uuid as string,
  });

  return (
    <OuterContainer>
      <InnerContainer
        isUnison={
          formDetailsQuery.data != null &&
          formDetailsQuery.data.structure.format === HiylloFormFormat.unison
        }
      >
        <div>
          {hasUFP ? (
            <Logo src={getRootURL() + "/ufplogo.png"} />
          ) : (
            <LogoTypography>{config.platformName}</LogoTypography>
          )}
        </div>
        {formDetailsQuery.isLoading ? (
          <Content>
            <LoadingSpinner />
          </Content>
        ) :
          formDetailsQuery.isError ?
            <Content>
              {formDetailsQuery.error.message}
            </Content>
            :
            formDetailsQuery.data.structure.format ===
              HiylloFormFormat.unison ? (
              <LoadedFormUnison
                form={formDetailsQuery.data}
                formUUID={uuid as string}
              />
            ) : (
              <LoadedFormSequential
                form={formDetailsQuery.data}
                formUUID={uuid as string}
              />
            )}
      </InnerContainer>
    </OuterContainer>
  );
});
