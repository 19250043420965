import React from "react";
import { Form } from "../../../ux/alpha";
import { useConfigPropertyHandle } from "../hooks/config-property-handle";
import { Button } from "@hiyllo/ux/button";
import { Input } from "@hiyllo/ux/input";

export const BasicTextConfigPropertyEditor = (props: {
  path: string;
  label: string;
}): JSX.Element => {
  const handle = useConfigPropertyHandle<string | null>(props.path);
  const nameInputRef = React.useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = React.useState<string | null>(null);

  const onSubmit = (): void => {
    const value = nameInputRef.current?.value;

    if (value == null) {
      return;
    }

    void handle.onChange(value === "" ? null : value);
  };

  if (handle.isLoading) {
    return <div />;
  }

  return (
    <>
      <div style={{ minWidth: 250, fontFamily: "hiyllo" }}>
        <div style={{ fontSize: 15, marginBottom: 5 }}>
          {props.label +
            (inputValue === handle.current || inputValue === null
              ? ""
              : " (Changed)")}
        </div>
        <div style={{ display: "flex", flexDirection: 'row', gap: 10, alignItems: "center" }}>
          <Input
            type="text"
            ref={nameInputRef}
            defaultValue={handle.current ?? ""}
            onChange={(evt) => {
              setInputValue(evt.target.value);
            }}
          />
          <Button label="Save" isLoading={handle.changeLoading} onClick={onSubmit} />
        </div>
      </div>
    </>
  );
};
