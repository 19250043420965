import React from "react";
import * as RetrieveDocumentHistoryBP from "../../../blueprints/stuff/retrieve-document-history";
import { StuffEDataParamsType } from "../../../types/navigation/edata";
import { usePath } from "@hiyllo/omni-router";
import { seamlessClient } from "../../../seamless-client";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { faArrowLeft, faExclamationTriangle, faFile } from "@fortawesome/pro-light-svg-icons";
import moment from "moment";
import { LeftSidebar } from "../../tokyo/components/left-sidebar";
import { MainContent } from "../../tokyo/components/main-content";
import { LSButton } from "../../tokyo/components/ls-button";
import { Sidebar } from "@hiyllo/ux/tokyo";
import { Editor } from "@hiyllo/editor";
import { useNavigateTo } from "@hiyllo/omni-continuity/main";
import { Features } from "../../../types/navigation/features";
import { UserChip } from "../../../ux/user-chip";


export const DocumentHistoryView = React.memo(function DocumentHistoryView(): JSX.Element {
  const [selectedVersionIndex, setSelectedVersionIndex] = React.useState<number>(0);
  const { uuid } = usePath().params as StuffEDataParamsType;

  if (uuid == null) {
    throw new Error("uuid is null");
  }

  const backToEditor = useNavigateTo({
    feature: Features.stuff,
    params: {
      view: 'document',
      uuid
    }
  });

  const documentHistoryQuery = seamlessClient.useQuery<RetrieveDocumentHistoryBP.Plug>(RetrieveDocumentHistoryBP, {
    uuid
  });

  if (documentHistoryQuery.isLoading) {
    return (
      <LoadingSpinnerFullView />
    );
  }

  if (documentHistoryQuery.isError) {
    return (
      <EmptySplash
        icon={faExclamationTriangle}
        label={documentHistoryQuery.error.message}
      />
    );
  }

  const selectedVersion = documentHistoryQuery.data.versions[selectedVersionIndex];

  return (
    <>
      <LeftSidebar>
        <Sidebar.Header
          label={`${documentHistoryQuery.data.document.name} History`}
        />
        <LSButton
          isActive={false}
          onClick={backToEditor}
          icon={{ fa: faArrowLeft }}
          label="Back to Editor"
        />
        <div style={{ height: 10 }} />
        {documentHistoryQuery.data.versions.map((v, i) => (
          <LSButton
            isActive={selectedVersionIndex === i}
            onClick={() => {
              setSelectedVersionIndex(i);
            }}
            icon={{ fa: faFile }}
            label={moment(v.date).format('h:mm a, ddd MMM Do')}
            key={v.date.valueOf()}
          />
        ))}
      </LeftSidebar>
      <MainContent noPadding>
        {selectedVersion != null && selectedVersion.contents.v2 !== true ?
          <Editor
            initialContents={selectedVersion.contents.delta.ops}
            readOnly
            isSaving={false}
            onContentsChanged={() => {
              // huh
            }}
            name={'Viewing version from ' + moment(selectedVersion.date).format('h:mm a, ddd MMM Do')}
            key={selectedVersion.date.valueOf()}
            extraElementBelowName={
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap", gap: "0.5em" }}>
                Edited by <UserChip userId={selectedVersion.author} />
              </div>
            }
            onExportBlob={() => {
              //
            }}
          />
          : null}
      </MainContent>
    </>
  );
});