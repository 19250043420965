import { Editor, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { SlateElementWithType } from "../types";

const LIST_TYPES = ["numbered-list", "bulleted-list"];

function isBlockActive(editor: ReactEditor, format: string) {
  const { selection } = editor;

  if (!selection) {
    return false;
  }

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n => (n as SlateElementWithType).type === format
    })
  );

  return !!match;
}

export function toggleBlock(editor: ReactEditor, format: string, children?: any[]) {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes((n as SlateElementWithType).type as string),
    split: true
  });

  const newProperties: Partial<SlateElementWithType> = {
    type: isActive ? "paragraph" : isList ? "list-item" : format
  };

  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: children ?? [] };
    Transforms.wrapNodes(editor, block);
  }
}