/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { SettingsType } from "../../types/settings/settings-type";

export type ParamsType = {
  key: keyof SettingsType;
};
export type ResponseType = SettingsType[keyof SettingsType] | null;
export const Endpoint = "settings/get";
export const Method = "GET";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 5,
  per: 2000,
};
export const paramsSchema = {"type":"object","properties":{"key":{"type":"string","enum":["email/signature"]}},"required":["key"],"$schema":"http://json-schema.org/draft-07/schema#"}