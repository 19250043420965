/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import type { MoopsyStream } from "@moopsyjs/core";

export type ParamsType = {
  rewrite: string;
  prompt: string;
} | {
  generate: string;
};
export type ResponseType = {
  text: MoopsyStream<string>;
};
export const Endpoint = "docs/generate-text";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const paramsSchema = {"anyOf":[{"type":"object","properties":{"rewrite":{"type":"string"},"prompt":{"type":"string"}},"required":["prompt","rewrite"]},{"type":"object","properties":{"generate":{"type":"string"}},"required":["generate"]}],"$schema":"http://json-schema.org/draft-07/schema#"}