import React from "react";
import { useChangeNodeData } from "../hooks/use-change-node-data";
import { SlateElementWithType } from "../types";
import { placeCaretAfterElement } from "../utils/place-caret-after-element";
import { EditorCtx } from "../editor-v2";

export type HiylloListData = {
  items: string[];
  style: "ordered" | "unordered";
};

const AutoResizingTextarea = React.memo(function AutoResizingTextarea(props: React.PropsWithChildren<React.TextareaHTMLAttributes<HTMLTextAreaElement>>): JSX.Element {
  const ref = React.useRef<HTMLTextAreaElement>(null);

  React.useEffect(() => {
    if (ref.current) {
      ref.current.style.height = "auto";
      ref.current.style.height = ref.current.scrollHeight + "px";
    }
  }, [props.value]);

  return <textarea {...props} ref={ref} />;
});

export const HiylloList = React.memo(function HiylloList(props: React.PropsWithChildren<{ element: SlateElementWithType, data: HiylloListData }>): JSX.Element {
  const changeNodeData = useChangeNodeData();
  const parentRef = React.useRef<HTMLDivElement>(null);
  const editor = React.useContext(EditorCtx);

  if (editor == null) throw new Error("editor is null");

  return (
    <div contentEditable={false} ref={parentRef}>
      {props.data.items.map((item, index) => (
        <div style={{ display: "flex", flexDirection: "row", gap: 10 }} key={index}>
          <div style={{ width: 15, flexShrink: 0, flexGrow: 0 }}>{index + 1}.</div>
          <AutoResizingTextarea
            id={`list-item-${index}`}
            value={item}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              e.preventDefault();
              e.stopPropagation();
              const data = { ...props.data, items: [...props.data.items] };

              data.items[index] = e.target.value;

              changeNodeData(props.element, data);
            }}
            onKeyDown={e => {
              e.stopPropagation();

              if (e.key === "Enter") {
                if (e.currentTarget.value === "") {
                  placeCaretAfterElement(editor, props.element);
                }
                else {
                  e.preventDefault();

                  const data = { ...props.data, items: [...props.data.items] };

                  data.items.splice(index + 1, 0, "");

                  changeNodeData(props.element, data);

                  setTimeout(() => {
                    const nextInput = document.getElementById(`list-item-${index + 1}`);

                    if (nextInput) {
                      nextInput.focus();
                    }
                  }, 50);
                }
              }
            }}
            style={{
              flexGrow: 1,
              background: "transparent",
              height: "max-content",
              border: "none",
              outline: "none",
              fontSize: "inherit",
              lineHeight: "inherit",
              color: "inherit",
              padding: 0,
              margin: 0,
              resize: "none",
            }}
            rows={1}
          />
        </div>
      ))}
      {props.children}
    </div>
  );
});