import { Editor, Transforms } from "slate";
import { ReactEditor } from "slate-react";

export function selectWholeLine(editor: ReactEditor): void {
  const { selection } = editor;


  if (selection) {
    // Find the closest block ancestor of the selection
    const blockEntry = Editor.above(editor, {
      // @ts-expect-error ---
      match: n => Editor.isBlock(editor, n),
    });

    if (blockEntry) {
      const [, blockPath] = blockEntry;
      // Create a range that covers the entire block
      const blockRange = Editor.range(editor, blockPath);

      // Update the selection to the block range
      Transforms.select(editor, blockRange);
    }
  }
}