import React from "react";
import { Cache } from "../../../platform/cache";
import { Card } from "@hiyllo/ux/surface";
import { styled } from "@hiyllo/ux/styled";
import { PriorityPill } from "../components/priority-pill";
import { StatusPill, AssigneePill, DueDatePill } from "@hiyllo/omni-tasks";
import { SizingPill } from "../components/sizing-pill";
import { CEInput } from "../../../ux/alpha/input";
import {
  type TaskStatusType,
  type PriorityType,
  type TaskItem,
  type TaskDueDateType,
  type TaskSizingType,
  TaskType,
  EnhancedTaskItem,
} from "../../../types/tasks/task-item";
import { useUpdateTaskPriority } from "../hooks/use-update-task-priority";
import { useUpdateTaskStatus } from "../hooks/use-update-task-status";
import { useUpdateTaskDueDate } from "../hooks/use-update-task-due-date";
import { useUpdateTaskSizing } from "../hooks/use-update-task-sizing";
import { useUpdateTaskTitle } from "../hooks/update-task-title";
import { useUpdateTaskDescription } from "../hooks/update-task-description";
import { useDebounce } from "../../../web-utils/use-debounce";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import * as TaskSpec from "../../../blueprints/tasks/pubsub/task-spec";
import * as MoveTaskBP from "../../../blueprints/tasks/move-task";
import * as ChangeTaskSprintBP from "../../../blueprints/tasks/change-task-sprint";
import * as ArchiveTaskBP from "../../../blueprints/tasks/archive-task";
import * as AddTaskAttachment from "../../../blueprints/tasks/add-task-attachment";
import { seamlessClient } from "../../../seamless-client";
import { Input } from "@hiyllo/ux/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  type IconDefinition,
  faLink,
  faCheckCircle,
  faUserPlus,
  faBoxArchive,
  faDiagramSuccessor,
  faDiagramSubtask,
  faPencil,
  faBellSlash,
  faBellPlus,
  faTimes,
  faFileUpload,
  faVideo,
  faExternalLink,
} from "@fortawesome/pro-light-svg-icons";
import { useDeriveURL, useNavigate } from "@hiyllo/omni-router";
import { Features } from "../../../types/navigation/features";
import { useSelf } from "@hiyllo/omni-continuity";
import { Tenant } from "../../../platform/tenancy";
import { Select } from "@hiyllo/ux/select";
import { useMyProjects } from "../hooks/use-my-projects";
import { ActivityView } from "./activity-view";
import { type UseMoopsyQueryRetValAny } from "@moopsyjs/react";
import { useAddTaskDependency } from "../hooks/use-add-task-dependency";
import { DependenciesView } from "./dependencies-view";
import { PillButton } from "@hiyllo/ux/pill-button";
import { TaskSearchOverlay } from "@hiyllo/omni-search";
import { useUpdateTaskParent } from "../hooks/use-update-task-parent";
import { TypePill } from "../components/type-pill";
import { Modal, useIsInModal } from "@hiyllo/ux/modal";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { useUpdateTaskFollowing } from "../hooks/use-update-task-following";
import { useGetMySprints, useGetTask, useUpdateTaskAssignee } from "@hiyllo/omni-tasks";
import { useTheme } from "@hiyllo/ux/theme";
import { useUploadFile } from "@hiyllo/omni-user-files/main";
import { FileGallery } from "../../../platform/file-gallery";
import { openWindow } from "../../../platform/open-window";
import { PillL, TagPill } from "../components/pill";

const Spacer = styled("div", { height: 10 });

const StyledTextarea = styled<"div", React.ComponentProps<typeof CEInput>>(
  CEInput,
  {
    width: "calc(100% - 30px)",
    padding: 15,
    minHeight: "1.25em",
    border: "none",
    fontSize: 18,
    borderRadius: 5,
  },
);

const Row = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 12.5,
  flexWrap: "wrap",
});

const MainRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: 20,
});

const TaskActionButtonContainer = styled("div", ({ $theme }) => ({
  background: $theme.midground,
  color: $theme.foreground,
  padding: 10,
  borderRadius: 5,
  display: "flex",
  flexDirection: "row",
  gap: 10,
  alignItems: "center",
  cursor: "pointer",
  whiteSpace: "nowrap",
}));

const Label = styled("div", {
  fontSize: 12,
  marginBottom: 5,
});

const TaskActionButton = React.memo(function TaskActionButton(props: {
  onClick: () => void;
  text: string;
  icon: IconDefinition;
}): JSX.Element {
  return (
    <TaskActionButtonContainer onClick={props.onClick}>
      <FontAwesomeIcon icon={props.icon} fixedWidth />
      {props.text}
    </TaskActionButtonContainer>
  );
});

const MoveTaskView = React.memo(function MoveTaskView(props: {
  task: Omit<TaskItem, "activity">;
  setMovingTask: (v: boolean) => void;
  taskQuery: UseMoopsyQueryRetValAny;
}): JSX.Element {
  const [projectUUID, setProjectUUID] = React.useState<string | null>(
    props.task.projectUUID,
  );
  const [sprintUUID, setSprintUUID] = React.useState<string | null>(
    props.task.sprintUUID ?? null,
  );
  const { projects } = useMyProjects();
  const sprintsQuery = useGetMySprints(null);

  const moveTaskMutation =
    seamlessClient.useMutation<MoveTaskBP.Plug>(MoveTaskBP);
  const changeTaskSprintMutation =
    seamlessClient.useMutation<ChangeTaskSprintBP.Plug>(
      ChangeTaskSprintBP,
    );

  const onChangeProject = React.useCallback(
    (projectUUID: string) => {
      props.setMovingTask(true);
      setProjectUUID(projectUUID);
      void moveTaskMutation
        .call({
          taskUUID: props.task.uuid,
          newProjectUUID: projectUUID,
        })
        .then(() => {
          props.setMovingTask(false);
        });
    },
    [moveTaskMutation, props],
  );

  const onChangeSprint = React.useCallback(
    (newSprintUUID: string) => {
      props.setMovingTask(true);
      setSprintUUID(newSprintUUID);
      void changeTaskSprintMutation
        .call({
          taskUUID: props.task.uuid,
          newSprintUUID,
        })
        .then(() => {
          props.setMovingTask(false);
        });
    },
    [changeTaskSprintMutation, props],
  );

  return (
    <Row>
      <div
        style={{
          flexGrow: 1,
          flexShrink: 0,
          flexBasis: "calc(50% - 10px)",
        }}
      >
        <Label>Project</Label>
        <Select
          fullWidth
          options={[
            {
              value: null,
              label: "<No Project>",
            },
            ...projects.data.map((p) => ({
              label: p.name,
              value: p.uuid,
            })),
          ]}
          value={
            projectUUID != null
              ? projects.data.find((p) => p.uuid === projectUUID)?.uuid ?? null
              : null
          }
          onChangeValue={(option) => onChangeProject(option as string)}
        />
      </div>
      <div
        style={{
          flexGrow: 1,
          flexShrink: 0,
          flexBasis: "calc(50% - 10px)",
        }}
      >
        <Label>Sprint</Label>
        <Select
          fullWidth
          options={[
            {
              value: null,
              label: "<No Sprint>",
            },
            ...(sprintsQuery.data?.sprints.map((p) => ({
              label: p.name,
              value: p.uuid,
            })) ?? []),
          ]}
          value={
            sprintUUID != null
              ? sprintsQuery.data?.sprints.find((p) => p.uuid === sprintUUID)
                ?.uuid ?? null
              : null
          }
          onChangeValue={(option) => onChangeSprint(option as string)}
        />
      </div>
    </Row>
  );
});

const OuterContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 20,
  pointerEvents: "none",
  height: "100%",
  fontFamily: "hiyllo",
});

const InnerContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 20,
  pointerEvents: "none",
  width: "100%",
  height: "100%",
});

const AttachmentTile = styled("div", ({ $theme }) => ({
  height: 160, width: 160, cursor: "pointer", userSelect: "none", display: "flex", flexDirection: "column",
  padding: 10, borderRadius: 5,
  alignItems: "center", justifyContent: "center",
  textAlign: "center",
  backgroundColor: $theme.midground1
}));

export const TaskView = React.memo(function TaskView(props: {
  uuid: string;
  withNavigationButtons?: boolean;
}): JSX.Element {
  const self = useSelf();
  const updateTaskPriorityMutation = useUpdateTaskPriority();
  const updateTaskStatusMutation = useUpdateTaskStatus();
  const updateTaskAssigneeMutation = useUpdateTaskAssignee();
  const updateTaskDueDateMutation = useUpdateTaskDueDate();
  const updateTaskSizingMutation = useUpdateTaskSizing();
  const updateTaskTitleMutation = useUpdateTaskTitle();
  const updateTaskFollowMutation = useUpdateTaskFollowing();
  const addTaskDependencyMutation = useAddTaskDependency();
  const updateTaskDescriptionMutation = useUpdateTaskDescription();
  const addTaskAttachmentMutation = seamlessClient.useMutation<AddTaskAttachment.Plug>(AddTaskAttachment);
  const isLoading =
    updateTaskPriorityMutation.isLoading ||
    updateTaskStatusMutation.isLoading ||
    updateTaskAssigneeMutation.isLoading ||
    updateTaskDueDateMutation.isLoading ||
    updateTaskSizingMutation.isLoading ||
    updateTaskTitleMutation.isLoading ||
    updateTaskDescriptionMutation.isLoading ||
    addTaskDependencyMutation.isLoading ||
    addTaskAttachmentMutation.isLoading;
  const titleInputRef = React.useRef<HTMLInputElement>(null);
  const descriptionInputRef = React.useRef<HTMLDivElement>(null);
  const cached = Cache.useCacheValue<EnhancedTaskItem>(`tasks.task.${props.uuid}`);
  const taskQuery = useGetTask({ uuid: props.uuid });
  const isInModal = useIsInModal();
  const live = taskQuery.data?.task;
  const [task, setTask] = React.useState<Omit<EnhancedTaskItem, "activity"> | null>(
    live ?? cached ?? null,
  );
  React.useEffect(() => {
    if (live != null) {
      setTask((current) => {
        if (current === null) {
          return live;
        } else if (current.lastUpdatedDate < live.lastUpdatedDate) {
          return live;
        }
        return current;
      });
    }
  }, [live]);
  const deriveURL = useDeriveURL();
  const [copied, setCopied] = React.useState<boolean>(false);
  const archiveTaskMutation =
    seamlessClient.useMutation<ArchiveTaskBP.Plug>(ArchiveTaskBP, {
      querySideEffects: [taskQuery],
    });
  const [showAddDependency, setShowAddDependency] =
    React.useState<boolean>(false);
  const [showAddChild, setShowAddChild] = React.useState<boolean>(false);

  seamlessClient.useSubscribeToTopic<TaskSpec.Typings>(
    TaskSpec,
    `[${Tenant}]task/${props.uuid}`,
    setTask,
  );

  /**
   * The logic here is stupid complicated, we should implement a package to simplify this.
   * - Nate
   */

  const setPriority = React.useCallback(
    (priority: PriorityType): void => {
      void updateTaskPriorityMutation.call({
        taskUUID: props.uuid,
        priority,
      });
    },
    [props.uuid, updateTaskPriorityMutation],
  );

  const setStatus = React.useCallback(
    (status: TaskStatusType) => {
      void updateTaskStatusMutation.call({
        taskUUID: props.uuid,
        status,
        order: null,
      });
    },
    [props.uuid, updateTaskStatusMutation],
  );

  const setFollowing = React.useCallback(
    (isFollowing: boolean) => {
      void updateTaskFollowMutation.call({
        taskUUID: props.uuid,
        isFollowing,
      });
    },
    [props.uuid, updateTaskFollowMutation],
  );

  const setDueDate = React.useCallback(
    (dueDate: TaskDueDateType | null) => {
      void updateTaskDueDateMutation.call({
        taskUUID: props.uuid,
        dueDate,
      });
    },
    [props.uuid, updateTaskDueDateMutation],
  );

  const setSizing = React.useCallback(
    (sizing: TaskSizingType | null) => {
      void updateTaskSizingMutation.call({
        taskUUID: props.uuid,
        sizing,
      });
    },
    [props.uuid, updateTaskSizingMutation],
  );

  const onChangeAssignee = React.useCallback(
    (assigneeUserId: string | null) => {
      void updateTaskAssigneeMutation
        .call({
          taskUUID: props.uuid,
          assigneeUserId,
        })
        .catch((err) => {
          alert(err.description);
        });
    },
    [props.uuid, updateTaskAssigneeMutation],
  );

  const assignYourself = React.useCallback(() => {
    updateTaskAssigneeMutation
      .call({
        taskUUID: props.uuid,
        assigneeUserId: self.userId,
      })
      .catch((err) => {
        alert(err.description);
      });
  }, [props.uuid, self.userId, updateTaskAssigneeMutation]);

  const copyLink = React.useCallback(() => {
    const url = deriveURL({
      feature: Features.tasks,
      params: {
        view: "task",
        taskUUID: props.uuid,
      },
    });
    void window.navigator.clipboard.writeText(url);
    setCopied(true);
  }, [deriveURL, props.uuid]);

  const openInNewTab = React.useCallback(() => {
    const url = deriveURL({
      feature: Features.tasks,
      params: {
        view: "task",
        taskUUID: props.uuid,
      },
    });
    openWindow(url);
  }, [deriveURL, props.uuid]);

  const [isTitleChanged, setTitleChanged] = React.useState(false);
  const updateTitleDebounce = useDebounce(1000, "overwrite");
  const onChangeTitle = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const isChanged = task?.title !== evt.target.value;
      setTitleChanged(isChanged);
      if (isChanged) {
        updateTitleDebounce(() => {
          setTitleChanged(false);
          void updateTaskTitleMutation.call({
            taskUUID: props.uuid,
            title: evt.target.value,
          });
        });
      }
    },
    [props.uuid, task?.title, updateTaskTitleMutation, updateTitleDebounce],
  );

  const [isDescriptionChanged, setDescriptionChanged] = React.useState(false);
  const updateDescriptionDebounce = useDebounce(1000, "overwrite");
  const onChangeDescription = React.useCallback(
    (evt: React.ChangeEvent<HTMLDivElement>) => {
      const isChanged = task?.description !== evt.target.innerText;
      setDescriptionChanged(isChanged);
      if (isChanged) {
        updateDescriptionDebounce(() => {
          setDescriptionChanged(false);
          void updateTaskDescriptionMutation.call({
            taskUUID: props.uuid,
            description: evt.target.innerText,
          });
        });
      }
    },
    [
      props.uuid,
      task?.description,
      updateDescriptionDebounce,
      updateTaskDescriptionMutation,
    ],
  );
  const [movingTask, setMovingTask] = React.useState(false);
  const updateTaskParentMutation = useUpdateTaskParent({
    querySideEffects: [taskQuery],
  });
  const [showParent, setShowParent] = React.useState(false);
  const [changeParent, setChangeParent] = React.useState(false);
  const $theme = useTheme();

  const onArchiveTask = React.useCallback(() => {
    void archiveTaskMutation.call({ taskUUID: props.uuid });
  }, [archiveTaskMutation, props.uuid]);
  const uploadFile = useUploadFile();

  const [attachFileLoading, setAttachFileLoading] = React.useState(false);
  const onAttachmentInputChanged = React.useCallback(
    async (evt: React.ChangeEvent<HTMLInputElement>) => {
      setAttachFileLoading(true);
      if (evt.target.files != null && evt.target.files.length > 0) {
        for (const file of [...evt.target.files]) {
          const { fsId } = await uploadFile(file);
          await addTaskAttachmentMutation.call({
            taskUUID: props.uuid,
            fsId
          });
        }
      }
      setAttachFileLoading(false);
    },
    [addTaskAttachmentMutation, props.uuid, uploadFile],
  );

  const navigate = useNavigate();
  const isArchived = task?.deleted?.value === true;
  const [showFileGallery, setShowFileGallery] = React.useState<string | null>(null);

  if (task == null) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {showFileGallery != null ?
        <Modal onClose={() => setShowFileGallery(null)}>
          <FileGallery
            files={[{ src: showFileGallery }]}
          />
        </Modal>
        : null}
      {showAddDependency ? (
        <TaskSearchOverlay
          onClose={() => setShowAddDependency(false)}
          onTaskSelected={(task) => {
            void addTaskDependencyMutation.call({
              taskUUID: props.uuid,
              dependencyUUID: task.uuid,
            });
            setShowAddDependency(false);
          }}
        />
      ) : null}
      {showAddChild ? (
        <TaskSearchOverlay
          onClose={() => setShowAddChild(false)}
          onTaskSelected={(task) => {
            void updateTaskParentMutation.call({
              taskUUID: task.uuid,
              parent: props.uuid,
            });
            setShowAddChild(false);
          }}
        />
      ) : null}
      <OuterContainer>
        <div
          style={{
            height: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {props.withNavigationButtons === true ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: 10,
                marginBottom: 20,
                pointerEvents: "auto",
              }}
            >
              <PillButton
                label="Go to Project"
                onClick={() => {
                  navigate({
                    feature: Features.tasks,
                    params: {
                      view: "project",
                      projectUUID: task.projectUUID,
                    },
                  });
                }}
              />
              {task.sprintUUID != null ? (
                <PillButton
                  label="Go to Sprint"
                  onClick={() => {
                    if (task.sprintUUID != null) {
                      navigate({
                        feature: Features.tasks,
                        params: {
                          view: "sprint",
                          sprintUUID: task.sprintUUID,
                        },
                      });
                    }
                  }}
                />
              ) : null}
            </div>
          ) : null}
          <InnerContainer>
            <DependenciesView task={task} />
            <Card
              color="background3"
              style={{ maxWidth: 800, pointerEvents: "auto" }}
            >
              <MainRow>
                <div style={{ overflow: "hidden" }}>
                  {isArchived ? (
                    <>
                      <div>Archived</div>
                      <Spacer />
                    </>
                  ) : null}
                  <Row>
                    <TypePill type={task.type} onChangeType={null} />
                    <PriorityPill
                      priority={task.priority}
                      onChangePriority={isArchived ? null : setPriority}
                    />
                    <StatusPill
                      status={task.status}
                      onChangeStatus={isArchived ? null : setStatus}
                    />
                    <DueDatePill
                      dueDate={task.dueDate}
                      onChangeDueDate={isArchived ? null : setDueDate}
                    />
                    <SizingPill
                      sizing={task.sizing}
                      onChangeSizing={isArchived ? null : setSizing}
                    />
                    <AssigneePill
                      assignee={task.assigneeUserId as string}
                      onChangeAssignee={isArchived ? null : onChangeAssignee}
                    />
                  </Row>
                  <Spacer />
                  <div style={{ marginBottom: task.tags.length > 0 ? 10 : 0, display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
                    {task.tags.map((tag) => (
                      <TagPill key={tag.uuid}>{tag.name}</TagPill>
                    ))}
                  </div>
                  <div
                    style={{ overflow: "hidden", textOverflow: "break-all" }}
                  >
                    <Input
                      type="text"
                      placeholder="What needs to be done?"
                      ref={titleInputRef}
                      fullWidth
                      defaultValue={task.title}
                      onChange={onChangeTitle}
                      multiline
                      containerStyle={{
                        padding: 5,
                        fontSize: 18,
                      }}
                    />
                  </div>
                  <Spacer />
                  <div>
                    <StyledTextarea
                      type="text"
                      placeholder="Add a description..."
                      inputRef={descriptionInputRef}
                      fullWidth
                      multiline
                      style={{ minHeight: "5em" }}
                      defaultValue={task.description}
                      onChange={onChangeDescription}
                    />
                  </div>
                  <Spacer />
                  {task != null ? (
                    <MoveTaskView
                      taskQuery={taskQuery}
                      task={task}
                      setMovingTask={setMovingTask}
                    />
                  ) : null}
                  <Spacer />
                  {taskQuery.data != null && task.type !== TaskType.epic ? (
                    <>
                      {showParent ? (
                        <Modal onClose={() => setShowParent(false)}>
                          <TaskView
                            uuid={taskQuery.data.parentTask?.uuid ?? ""}
                          />
                        </Modal>
                      ) : null}
                      {changeParent ? (
                        <TaskSearchOverlay
                          onClose={() => setChangeParent(false)}
                          onTaskSelected={(task) => {
                            void updateTaskParentMutation.call({
                              taskUUID: props.uuid,
                              parent: task.uuid,
                            });
                            setChangeParent(false);
                          }}
                          typeFilter={
                            task.type === TaskType.story ? TaskType.epic : null
                          }
                        />
                      ) : null}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 7.5,
                        }}
                      >
                        Parent
                        {taskQuery.data.parentTask != null ? (
                          <>
                            <PillL onClick={() => setShowParent(true)}>
                              {taskQuery.data.parentTask.title}
                            </PillL>
                            <CircleButton
                              size={30.5}
                              icon={faTimes}
                              color={$theme.midground1}
                              isLoading={updateTaskParentMutation.isLoading}
                              onClick={() => {
                                void updateTaskParentMutation.call({
                                  taskUUID: props.uuid,
                                  parent: null,
                                });
                              }}
                            />
                          </>
                        ) : (
                          <PillL>None</PillL>
                        )}
                        <CircleButton
                          size={30.5}
                          icon={faPencil}
                          onClick={() => setChangeParent(true)}
                        />
                      </div>
                      <Spacer />
                    </>
                  ) : null}
                  {task.attachments != null && task.attachments.length > 0 ?
                    <div style={{ paddingBottom: 15 }}>
                      <div style={{ fontSize: 20, fontFamily: "hiyllo", paddingBottom: 10 }}>Attachments</div>
                      <div style={{ display: "flex", flexDirection: "row", gap: 10, overflowX: "auto", overflowY: "hidden", height: 180 }}>
                        {task.attachments.map((attachment) => (
                          <div key={attachment.uuid}>
                            {attachment.mimeType.startsWith("image/") ? (
                              <img src={attachment.src} style={{ height: "100%", width: "auto" }} onClick={() => setShowFileGallery(attachment.src)} />
                            ) : null}
                            {attachment.mimeType.startsWith("video/") ? (
                              <AttachmentTile onClick={() => setShowFileGallery(attachment.src)}>
                                <FontAwesomeIcon icon={faVideo} />
                                <div style={{ wordBreak: "break-word" }}>
                                  {attachment.name}
                                </div>
                              </AttachmentTile>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    </div>
                    : null}
                  <div style={{ height: 18.5 }}>
                    {isLoading ||
                      isTitleChanged ||
                      isDescriptionChanged ||
                      movingTask ? (
                      <Row>
                        <LoadingSpinner />
                        Saving Changes...
                      </Row>
                    ) : (
                      <Row>
                        <FontAwesomeIcon icon={faCheckCircle} />
                        All Changed Saved
                      </Row>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                  }}
                >
                  <TaskActionButton
                    icon={faLink}
                    text={copied ? "Copied!" : "Copy Link"}
                    onClick={copyLink}
                  />
                  {isInModal ?
                    <TaskActionButton
                      icon={faExternalLink}
                      text={"Open in New Tab"}
                      onClick={openInNewTab}
                    />
                    : null}

                  {task.assigneeUserId !== self.userId ? (
                    <TaskActionButton
                      icon={faUserPlus}
                      text="Assign to Me"
                      onClick={assignYourself}
                    />
                  ) : null}
                  <TaskActionButton
                    icon={faDiagramSuccessor}
                    text={"Add Dependency"}
                    onClick={() => setShowAddDependency(true)}
                  />
                  <label>
                    <input type="file" style={{ display: "none" }} onChange={onAttachmentInputChanged} />
                    <TaskActionButton
                      icon={faFileUpload}
                      text={attachFileLoading ? "Uploading..." : "Attach File"}
                      onClick={() => { }}
                    />
                  </label>
                  <TaskActionButton
                    icon={faDiagramSubtask}
                    text={"Add Child"}
                    onClick={() => setShowAddChild(true)}
                  />
                  {task.followers.includes(self.userId) ? (
                    <TaskActionButton
                      icon={faBellSlash}
                      text={"Unfollow"}
                      onClick={() => setFollowing(false)}
                    />
                  ) : (
                    <TaskActionButton
                      icon={faBellPlus}
                      text={"Follow"}
                      onClick={() => setFollowing(true)}
                    />
                  )}
                  {task.deleted?.value !== true ? (
                    <TaskActionButton
                      icon={faBoxArchive}
                      text="Archive Task"
                      onClick={onArchiveTask}
                    />
                  ) : null}
                </div>
              </MainRow>
            </Card>
            <ActivityView
              taskUUID={props.uuid}
              createdDate={task.createdDate}
              creatorUserId={task.creatorUserId}
            />
          </InnerContainer>
        </div>
      </OuterContainer>
    </>
  );
});
