export interface QuoteType {
  quote: string;
  author: string;
}

export const quotes: QuoteType[] = [
  {
    quote:
      "I've always liked quiet people. You never know if they're dancing in their daydream or if they're carrying the weight of the world.",
    author: "John Green",
  },
  {
    quote:
      "If you went back and fixed all the mistakes you've ever made, you would erase yourself",
    author: "Unknown",
  },
  {
    quote: "The only way to do great work is to love what you do.",
    author: "Steve Jobs",
  },
  {
    quote:
      "Imperfection is beauty, madness is genius and it's better to be absolutely ridiculous than absolutely boring.",
    author: "Marilyn Monroe",
  },
  {
    quote:
      "It is possible to commit no errors and still lose. That is not weakness, that is life.",
    author: "Jean-Luc Picard",
  },
  {
    quote: "Perfection is unattainable. But better is still possible.",
    author: "ND",
  },
  {
    quote: "Fear exists for one purpose: to be conquered.",
    author: "Kathryn Janeway",
  },
  {
    quote: "Dreams are something you have to believe in, I almost gave up on mine, keep your dreams alive.",
    author: "Ke Huy Quan"
  },
  {
    quote: "Dreams are just dreams, until they're not",
    author: "ND"
  },
  {
    quote: "I hate literally every step in the filmmaking process. The only thing I hate more than making a film is not making a film.",
    author: "Defunctland"
  },
  {
    quote: "If no art makes you feel anything, make your own art and feel something",
    author: "Jenna Marbles"
  }
];
