import { Node, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

type UploadFileFnOptions = {
  onProgress?: (progress: number) => void;
  tag?: string | null;
};

export const withImageDragIn = (editor: ReactEditor, uploadFile: (file: File, opts?: UploadFileFnOptions) => Promise<{
  fsId: string;
  src: string;
}>): ReactEditor => {
  const { insertData } = editor;

  // Handle pasting and dropping images
  editor.insertData = (data) => {
    const text = data.getData('text/plain');
    const { files } = data;

    if (files && files.length > 0) {
      for (const file of Array.from(files)) {
        void uploadFile(file).then((result) => {
          insertHiylloImage(editor, result);
        });
      }
    } else if (isImageUrl(text)) {
      insertURLImage(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const insertURLImage = (editor: ReactEditor, url: string) => {
  const imageNode = { type: 'image', url, children: [{ text: '' }] };
  Transforms.insertNodes(editor, imageNode);
};


// Helper function to insert an image URL
const insertHiylloImage = (editor: ReactEditor, result: { src: string, fsId: string }) => {
  const imageElement = {
    type: "hiylloImage",
    src: result.src,
    fsId: result.fsId,
    children: [{ text: " " }]
  } as unknown as Node;
  Transforms.insertNodes(editor, imageElement);
};

// Helper function to check if a text is an image URL
const isImageUrl = (url: string) => {
  return /\.(jpeg|jpg|gif|png)$/.test(url);
};
