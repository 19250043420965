import React from "react";
import { Node, Transforms } from "slate";
import { ReactEditor } from "slate-react";

/**
 * Handles uploading of pasted images
 */
export function useOnPaste(editor: ReactEditor, onImageUploaded: ((image: Blob) => Promise<{
  src: string;
  fsId: string;
}>) | undefined) {
  return React.useCallback((evt: React.ClipboardEvent) => {
    const items = evt.clipboardData?.items ?? [];

    const kinds = Array.from(items).map(i => i.kind);

    if (items.length === 0 || !kinds.some(k => k === "file")) return;

    evt.preventDefault();
    evt.stopPropagation();

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === "file") {
        const file = item.getAsFile();
        if (file == null) continue;

        if (onImageUploaded == null) {
          return;
        }

        void onImageUploaded(file).then((result) => {
          const imageElement = {
            type: "hiylloImage",
            src: result.src,
            fsId: result.fsId,
            children: [{ text: " " }]
          } as unknown as Node;
          Transforms.insertNodes(editor, imageElement);
        });
      }
    }
  }, [editor, onImageUploaded]);
}