/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { UserProfileSlimType } from "../continuity/user-profile";
import { GeoCoords } from "../geo/coords";
import type { TagDBItem } from "./tag";
import { TaskCommentType } from "./task-comment";

export interface TaskActivityBaseType {
  type: string;
  actor: string;
  date: Date;
}

export interface CommentTaskActivityType extends TaskActivityBaseType {
  type: "comment";
  content: {
    text: string;
  };
}

export interface AssignmentActivity extends TaskActivityBaseType {
  type: "assignment";
  previousAssignee: string | null;
  newAssignee: string | null;
}

export interface StatusChangeActivity extends TaskActivityBaseType {
  type: "statuschange";
  previousStatus: TaskStatusType;
  newStatus: TaskStatusType;
}

export interface DueDateChangeActivity extends TaskActivityBaseType {
  type: "duedatechange";
  oldDueDate: TaskDueDateType | null;
  newDueDate: TaskDueDateType | null;
}

export interface SizingChangeActivity extends TaskActivityBaseType {
  type: "sizingchange";
  oldSizing: TaskSizingType | null;
  newSizing: TaskSizingType | null;
}

export interface ProjectChangeActivity extends TaskActivityBaseType {
  type: "projectchange";
  oldProjectUUID: string;
  newProjectUUID: string;
}

export interface TitleChangeActivity extends TaskActivityBaseType {
  type: "titlechange";
  oldTitle: string;
  newTitle: string;
}

export interface DescriptionChangeActivity extends TaskActivityBaseType {
  type: "descriptionchange";
  oldDescription: string;
  newDescription: string;
}

export interface AttachmentAddActivity extends TaskActivityBaseType {
  type: "attachmentadded";
  attachment: TaskAttachmentType;
}

export enum TaskStatusColorEnum {
  red = "red",
  amber = "amber",
  green = "green",
  blue = "blue",
  gray = "gray",
}

export enum TaskType {
  task = "task",
  story = "story",
  epic = "epic",
}

export const LABEL_BY_TASK_TYPE: Record<TaskType, string> = {
  [TaskType.task]: "Task",
  [TaskType.story]: "Story",
  [TaskType.epic]: "Epic",
} as const;

export type TaskActivityType =
  | CommentTaskActivityType
  | AssignmentActivity
  | StatusChangeActivity
  | DueDateChangeActivity
  | SizingChangeActivity
  | ProjectChangeActivity
  | TitleChangeActivity
  | DescriptionChangeActivity
  | AttachmentAddActivity
  ;

export type TaskStatusType_V1 =
  | "open"
  | "in-progress"
  | "pending"
  | "internal-review"
  | "client-review"
  | "awaiting-deployment"
  | "done";
export interface TaskStatusType {
  id: string;
  label: string;
  color: TaskStatusColorEnum;
}

export interface TaskCompletionDetailsType {
  releasedIn: string | null;
  deliveredTo: string | null;
}

export interface TaskSizingType {
  value: number;
  in: "work-hours" | "work-days" | "work-weeks" | "work-months" | "points";
  type: "explicit" | "automatic";
}

/**
 * Underlying system uses P0-P4 but we can map these to other values (e.g. "high" | "medium" | "low")
 * on the frontend
 */
export type PriorityType = 0 | 1 | 2 | 3 | 4;

export interface TaskDueDateType {
  date: Date;
  as: "day" | "day-and-time";
}

export interface TaskItem_V1 {
  title: string;
  description: string;
  creatorUserId: string;
  assigneeUserId: string | null;
  uuid: string;
  projectUUID: string;
  createdDate: Date;
  lastUpdatedDate: Date;
  activity: Array<TaskActivityType>;
  completionDetails: null | TaskCompletionDetailsType;
  status: TaskStatusType_V1;
  priority: PriorityType;
  tags: TagDBItem[];
  idNumber: number;
  comments?: TaskCommentType[];
  followers: string[];
}

export interface TaskReminderCriteriaLocation {
  label: string;
  coords: GeoCoords;
}

export interface TaskReminderCriteria {
  location: TaskReminderCriteriaLocation | null;
  date: Date | null;
}

export enum TaskReminderIdEnum {
  dateTime = "dateTime",
  location = "location",
}

export interface TaskAttachmentType {
  uuid: string;
  fsId: string;
  mimeType: string;
  name: string;
  size: number;
}

export interface EnhancedTaskAttachmentType extends TaskAttachmentType {
  src: string;
}

export interface TaskItem {
  title: string;
  description: string;
  status: TaskStatusType;
  priority: PriorityType;
  tags: TagDBItem[];
  dueDate: TaskDueDateType | null;
  /**
   * The total size
   */
  sizing: TaskSizingType | null;
  /**
   * The remaining size (excluding completed tasks)
   */
  remainingSize: TaskSizingType | null;
  assigneeUserId: string | null;
  type: TaskType;
  parentUUID?: string | null;

  projectUUID: string;
  sprintUUID?: string | null;
  formerSprints?: string[] | null;

  creatorUserId: string;
  uuid: string;
  shortId: string;
  idNumber: number;
  deleted: null | { value: true; by: string; on: Date };
  createdDate: Date;
  lastUpdatedDate: Date;
  activity: Array<TaskActivityType>;
  completionDetails: null | TaskCompletionDetailsType;
  comments?: TaskCommentType[];
  followers: string[];
  dueReminderSent?: boolean;
  dependencyUUIDs?: string[];
  reminderCriteria?: TaskReminderCriteria | null;
  remindersSent?: Array<TaskReminderIdEnum>;
  attachments?: Array<TaskAttachmentType>
  _v: 2;
}

export interface EnhancedTaskItem extends Omit<TaskItem, "activity"> {
  projectName: string | null;
  sprintName: string | null;
  assignee: null | UserProfileSlimType;
  reminderCriteria: TaskReminderCriteria | null;
  attachments: EnhancedTaskAttachmentType[];
}

export const DEFAULT_STATUSES = [
  { id: "todo", label: "To-Do", color: TaskStatusColorEnum.gray },
  { id: "inprogress", label: "In Progress", color: TaskStatusColorEnum.blue },
  { id: "inreview", label: "In Review", color: TaskStatusColorEnum.blue },
  { id: "done", label: "Done", color: TaskStatusColorEnum.green },
  { id: "wontdo", label: "Wont Do", color: TaskStatusColorEnum.red },
];
export type ListTasksSlimTaskType = {
  title: string;
  status: TaskStatusType;
  priority: PriorityType;
  dueDate: TaskDueDateType | null;
  sizing: TaskSizingType | null;
  remainingSize: TaskSizingType | null;
  // assignee: null | UserProfileSlimType;
  assigneeUserId: null | string;
  shortId: string;
  uuid: string;
  projectUUID: string;
  lastUpdatedDate: Date;
  type: TaskType;
  parentUUID?: string | null;
  sprintUUID?: string | null;
  deleted: null | { value: true; by: string; on: Date };
  reminderCriteria: TaskReminderCriteria | null;
  projectName: string | null;
  sprintName: string | null;
};
