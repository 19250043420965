/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  token: string;
  name: string;
};
export type ResponseType = {
  jwt: string;
  meetingServerURL: string;
  room: string;
  label: string | null;
  started: Date;
  isRecording: boolean;
};
export const Endpoint = "meet/join-meeting-with-spectator-token";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const isPublic = true;
export const paramsSchema = {"type":"object","properties":{"token":{"type":"string"},"name":{"type":"string"}},"required":["name","token"],"$schema":"http://json-schema.org/draft-07/schema#"}