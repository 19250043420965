import React from "react";
import { LeftSidebar } from "../../components/left-sidebar";
import { MainContent } from "../../components/main-content";
import { type FormsEDataParamsType } from "@hiyllo/omni-common/src/types/navigation/edata";
import { Sidebar } from "@hiyllo/ux/tokyo";
import {
  FeatureRouter,
  type FeatureRoutesType,
} from "../../navigation/feature-router";
import { EditForm } from "../../../forms/views/edit-form";
import { ViewResponses } from "../../../forms/views/view-responses";

const FormsRoutes: FeatureRoutesType<FormsEDataParamsType> = {
  "create-form": () => <div>create form</div>,
  edit: EditForm,
  "my-forms": () => <div>my forms</div>,
  respond: () => <div>respond</div>,
  "view-responses": ViewResponses,
};

const DefaultRoute: FormsEDataParamsType = { view: "my-forms" };

export const FormsFeature = React.memo(function FormsFeature(props: {
  params: FormsEDataParamsType;
}): JSX.Element {
  return (
    <>
      <LeftSidebar>
        <Sidebar.Header label="Forms" />
      </LeftSidebar>
      <MainContent noPadding>
        <FeatureRouter routes={FormsRoutes} default={DefaultRoute} />
      </MainContent>
    </>
  );
});
