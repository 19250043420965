import React from "react";
import { Card } from "@hiyllo/ux/surface";
import { styled } from "@hiyllo/ux/styled";
import { ProjectSelect, SprintSelect, TaskPillsRow, useCreateTask, useGetMySprints } from "@hiyllo/omni-tasks";
import { useSelf } from "@hiyllo/omni-continuity";
import { RouterContext, useDeriveURL, useNavigate, usePath } from "@hiyllo/omni-router";
import { Input, useInputControl } from "@hiyllo/ux/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type MoopsyError } from "@moopsyjs/core";
import { CheckboxInput } from "@hiyllo/ux/checkbox-input";
import { useShowDialog } from "@hiyllo/ux/dialogs";
import { AnimateChangeInHeight } from "@hiyllo/ux/animation";
import { Button } from "@hiyllo/ux/button";
import { Structure } from "@hiyllo/ux/structure";
import * as GetDraftTaskRecommendedProjectBP from "../../../blueprints/tasks//get-draft-task-recommended-project";

import { Textarea } from "../../../ux/alpha/input";
import { StatusPill, AssigneePill, DueDatePill } from "@hiyllo/omni-tasks";
import {
  type TaskStatusType,
  type PriorityType,
  DEFAULT_STATUSES,
  type TaskSizingType,
  type TaskDueDateType,
} from "../../../types/tasks/task-item";
import { SizingPill } from "../components/sizing-pill";
import { Features } from "../../../types/navigation/features";
import { isCommandKeyPressed } from "../../../platform/web/is-command-key-pressed";
import { PriorityColorMap } from "../maps/priority-colors-map";
import { PriorityIconsMap } from "../maps/priority-icons-map";
import { ErrorText } from "../../../ux/error";
import { useConfig } from "../../../platform/config/config-context";
import { useIsSolo } from "../../../platform/hooks/use-is-solo";
import { Priorites, usePriorityLabels } from "../constants/priorities";
import { TaskCardQueryWrapper } from "../components/task-card";
import { seamlessClient } from "../../../seamless-client";
import { useDebounce } from "@hiyllo/ux/use-debounce";
import { NymblPill } from "../../../ux/nymbl-pill";
import { Typography } from "@hiyllo/ux/typography";
import { KeyCombinationHint } from "../../stuff/documents/v2/editor-v2";
import { HotkeyEvent, KeyCombination, useHotkey } from "@hiyllo/ux/hotkeys";

const Header = styled("div", {
  fontSize: 24,
  lineHeight: "1.25em",
});

const Spacer = styled("div", { height: 15 });

const StyledTextArea = styled(Textarea, ({ $theme }) => ({
  width: "calc(100% - 30px)",
  padding: 15,
  minHeight: "1.25em",
  border: "none",
  fontSize: 18,
  borderRadius: 10,
  background: $theme.midground1
}));

const Label = styled("div", {
  fontSize: 12,
  marginBottom: 5,
});

const PriorityRowSelectRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
});

const PriorityRowPill = styled<"div", { color: string; active: boolean }>(
  "div",
  ({ color, active }) => ({
    padding: 5,
    paddingLeft: 5,
    paddingRight: 7.5,
    color: active ? "white" : color,
    backgroundColor: active ? color : "transparent",
    borderColor: color,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 20,
    fontSize: 12,
    gap: 2.5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  }),
);

const PriorityRowSelect = React.memo(function PriorityRowSelect(props: {
  priority: PriorityType;
  onChangePriority: (priority: PriorityType) => void;
}): JSX.Element {
  const pcm = PriorityColorMap();
  const labels = usePriorityLabels();

  return (
    <PriorityRowSelectRow>
      {Priorites.map((p) => (
        <PriorityRowPill
          key={p}
          color={pcm[p]}
          active={props.priority === p}
          onClick={() => props.onChangePriority(p)}
        >
          <FontAwesomeIcon icon={PriorityIconsMap[p]} fixedWidth />
          {labels[p]}
        </PriorityRowPill>
      ))}
    </PriorityRowSelectRow>
  );
});

interface PropsType {
  projectUUID: string | null;
  sprintUUID: string | null;
  title?: string | null;
  description?: string | null;
  noRedirect?: boolean;
  onTaskCreated?: ((taskUUID: string) => void) | null;
}

const createTaskCombination: KeyCombination = ["meta", "Enter"];

const CreateNewTaskViewInner = React.memo(function CreateNewTaskViewInner(
  props: PropsType,
): JSX.Element {
  const [projectUUID, setProjectUUID] = React.useState<string | null>(
    props.projectUUID,
  );
  const [sprintUUID, setSprintUUID] = React.useState<string | null>(
    props.sprintUUID,
  );
  const self = useSelf();
  const titleInputControl = useInputControl({});
  const descriptionInputControl = useInputControl({});
  const [priority, setPriority] = React.useState<PriorityType>(0);
  const [status, setStatus] = React.useState<TaskStatusType>(
    DEFAULT_STATUSES[0],
  );
  const [assigneeUserId, setAssignee] = React.useState<string | null>(
    self.userId,
  );
  const [dueDate, setDueDate] = React.useState<TaskDueDateType | null>(null);
  const [sizing, setSizing] = React.useState<TaskSizingType | null>(null);
  const [createAnotherTask, setCreateAnotherTask] =
    React.useState<boolean>(false);
  const navigate = useNavigate();
  const sprintsQuery = useGetMySprints(null);
  const [success, setSuccess] = React.useState<boolean>(false);

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  React.useEffect(() => {
    setSuccess(false);
  }, [priority, dueDate, sizing, projectUUID, sprintUUID, status, assigneeUserId]);

  React.useEffect(() => {
    setProjectUUID(props.projectUUID);
  }, [props.projectUUID]);

  const [guessedProjectUUID, setGuessedProjectUUID] = React.useState<string | null>(null);

  const guessProjectUUIDMutation = seamlessClient.useMutation<GetDraftTaskRecommendedProjectBP.Plug>(GetDraftTaskRecommendedProjectBP);

  const debounce = useDebounce(500, "overwrite");
  React.useEffect(() => {
    if (titleInputControl.value.length > 0) {
      debounce.debounce(() => {
        const params = {
          title: titleInputControl.valueRef.current,
          description: descriptionInputControl.valueRef.current,
        };
        console.log("Guessing from", params);
        void guessProjectUUIDMutation.call(params).then((res) => {
          console.log("Guessed", res);
          setGuessedProjectUUID(res.projectUUID);
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleInputControl.value, descriptionInputControl.value]);

  const showDialog = useShowDialog();
  const path = usePath();
  const deriveURL = useDeriveURL();
  const { createTask: doCreateTask, isLoading } = useCreateTask({
    onSimilarTaskWarning: async (similarTask) => {
      const value = await new Promise<boolean>((resolve) => {
        showDialog({
          title: "Similar Task",
          message: `A similar task (${similarTask.title}) already exists. Do you want to create another task?`,
          onCancel: () => resolve(false),
          extras: (
            <RouterContext.Provider
              value={{
                path,
                navigate,
                deriveURL,
              }}
            >
              <div style={{ boxShadow: "rgba(0,0,0,0.25) 0px 0px 20px 20px", borderRadius: 15, marginTop: 10, marginBottom: 10 }}>
                <TaskCardQueryWrapper
                  taskUUID={similarTask.uuid}
                />
              </div>
            </RouterContext.Provider>
          ),
          buttons: [
            {
              type: 'primary',
              label: "Continue Anyway",
              onClick: () => {
                resolve(true);
              },
            },
            {
              type: 'secondary',
              label: "Cancel",
              onClick: () => {
                resolve(false);
              },
            },
          ]
        });
      });

      return value;
    },
    onErrorMessage: setErrorMessage,
  });

  const createTask = React.useCallback(
    (createAnother?: boolean) => {
      const finalProjectUUID = projectUUID ?? guessedProjectUUID;

      if (finalProjectUUID == null) {
        setErrorMessage("Please select a project");
        return;
      }

      const title = titleInputControl.value;
      const description = descriptionInputControl.value;

      void doCreateTask({
        title,
        description,
        status,
        priority,
        dueDate,
        sizing,
        assigneeUserId,
        projectUUID: finalProjectUUID,
        parentUUID: null,
        sprintUUID,
      })
        .then(async (res) => {
          titleInputControl.update("");
          descriptionInputControl.update("");

          if (props.onTaskCreated != null && res?.taskUUID != null) {
            return props.onTaskCreated(res.taskUUID);
          }

          if (res != null) {
            if (
              !createAnotherTask &&
              createAnother !== true &&
              props.noRedirect !== true
            ) {
              if (sprintUUID != null) {
                const sprint = sprintsQuery.data?.sprints.find(
                  (s) => s.uuid === sprintUUID,
                );
                const teamUUID = sprint?.teamUUID;

                if (teamUUID != null) {
                  navigate({
                    feature: Features.tasks,
                    params: {
                      view: "sprint-planning",
                      sprintUUID,
                      teamUUID,
                    },
                  });

                  return;
                }
              }

              navigate({
                feature: Features.tasks,
                params: {
                  view: "project",
                  projectUUID: finalProjectUUID,
                },
              });
            } else {
              setSuccess(true);
            }
          }
        })
        .catch((err: MoopsyError) => {
          setErrorMessage(err.description);
        });
    },
    [assigneeUserId, createAnotherTask, descriptionInputControl, doCreateTask, dueDate, guessedProjectUUID, navigate, priority, projectUUID, props, sizing, sprintUUID, sprintsQuery.data?.sprints, status, titleInputControl],
  );

  const onCreateTask = React.useCallback((evt: HotkeyEvent) => {
    createTask(evt.shiftKey);
  }, [createTask]);

  useHotkey(createTaskCombination, onCreateTask);

  const onKeyDown = React.useCallback(
    (evt: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>) => {
      setSuccess(false);
      if (isCommandKeyPressed(evt) && evt.key === "Enter") {
        createTask(evt.shiftKey);
      }
    },
    [createTask],
  );

  const config = useConfig();
  const isSolo = useIsSolo();

  return (
    <>
      <Card color="background3">
        <Header>Create Task</Header>
        <Spacer />
        <TaskPillsRow>
          <PriorityRowSelect
            priority={priority}
            onChangePriority={setPriority}
          />
          <StatusPill status={status} onChangeStatus={setStatus} />
          <DueDatePill dueDate={dueDate} onChangeDueDate={setDueDate} />
          {!isSolo ? (
            <>
              <SizingPill sizing={sizing} onChangeSizing={setSizing} />
              <AssigneePill
                assignee={assigneeUserId}
                onChangeAssignee={setAssignee}
              />
            </>
          ) : null}
        </TaskPillsRow>
        <Spacer />
        <div>
          <Input
            onKeyDown={onKeyDown}
            type="text"
            autoFocus
            placeholder="What needs to be done?"
            fullWidth
            multiline
            containerStyle={{
              padding: 5,
              fontSize: 18,
            }}
            {...titleInputControl.inputProps}
          />
        </div>
        <Spacer />
        <div>
          <StyledTextArea
            onKeyDown={onKeyDown}
            type="text"
            placeholder="Optionally, add a description..."
            fullWidth
            style={{ minHeight: "5em" }}
            {...descriptionInputControl.inputProps}
          />
        </div>
        <TaskPillsRow style={{ alignItems: "flex-start" }}>
          <div
            style={{
              flexGrow: 1,
              flexShrink: 0,
              flexBasis: "calc(50% - 10px)",
            }}
          >
            <Structure.LColumn10>
              <div>
                <Typography.Label style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  Project
                </Typography.Label>
                <ProjectSelect
                  projectUUID={projectUUID ?? guessedProjectUUID}
                  setProjectUUID={setProjectUUID}
                  disallowNullSelection={isSolo}
                />
              </div>
              <AnimateChangeInHeight>
                {projectUUID == null && guessedProjectUUID != null ?
                  <NymblPill>
                    I selected a project based on your new task
                  </NymblPill>
                  : null}
              </AnimateChangeInHeight>
            </Structure.LColumn10>
          </div>
          {!isSolo ? (
            <>
              <div
                style={{
                  flexGrow: 1,
                  flexShrink: 0,
                  flexBasis: "calc(50% - 10px)",
                }}
              >
                <Label>Sprint</Label>
                <SprintSelect
                  value={sprintUUID ?? null}
                  onChangeValue={(option) => setSprintUUID(option as string)}
                />
              </div>
            </>
          ) : null}
        </TaskPillsRow>
        <Spacer />
        {config.restricted !== true ? (
          <Structure.Row>
            <Button
              label={
                <>
                  Create Task
                  <KeyCombinationHint
                    combination={createTaskCombination}
                  />
                </>
              }
              isLoading={isLoading}
              onClick={() => createTask()}
              success={success}
              successMessage="Created!"
            />
            <CheckboxInput
              value={createAnotherTask}
              onChange={setCreateAnotherTask}
              label={"Create Another Task"}
            />
          </Structure.Row>
        ) : (
          <div>Please fix your billing issues to create new tasks</div>
        )}
        <AnimateChangeInHeight>
          {errorMessage != null ? <div style={{ height: 15 }} /> : null}
          <ErrorText message={errorMessage} />
        </AnimateChangeInHeight>
      </Card>
    </>
  );
});

export const CreateNewTaskView = React.memo(function CreateNewTaskView(
  props: PropsType,
): JSX.Element {
  return <CreateNewTaskViewInner {...props} />;
});
