import React from "react";
import {
  type AlertType,
  type RSVPActionType,
} from "@hiyllo/omni-common/src/types/alerts/alert";
import { type UseMoopsyQueryRetValAny } from "@moopsyjs/react";
import * as MarkAlertsReadBP from "../../../../blueprints/alerts/mark-alerts-read";
import * as RetrieveEventBP from "../../../../blueprints/calendar/retrieve-event";
import * as RSVPBP from "../../../../blueprints/calendar/rsvp-to-event";
import { seamlessClient } from "../../../../seamless-client";
import { useSelf } from "@hiyllo/omni-continuity";
import {
  type EventGuestType,
  RSVPEnum,
} from "@hiyllo/omni-common/src/types/calendar/calendar-event";
import { ActionRepeatContextEnum } from "@hiyllo/omni-common/src/types/calendar/repeating";
import { AnimateChangeInHeight } from "@hiyllo/ux/animation";
import { RSVPButton } from "./alert-card-components";
import { useNavigate } from "@hiyllo/omni-router";
import { ContinuityMeetingContext } from "../../../../main/meeting-provider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/pro-light-svg-icons";

export const RSVPAction = React.memo(function RSVPAction(props: {
  action: RSVPActionType;
}): JSX.Element {
  const retrieveEventQuery =
    seamlessClient.useQuery<RetrieveEventBP.Plug>(RetrieveEventBP, {
      eventUUID: props.action.action.eventUUID,
    });
  const rsvpMutation = seamlessClient.useMutation<RSVPBP.Plug>(RSVPBP, {
    querySideEffects: [retrieveEventQuery],
  });
  const self = useSelf();

  const event = retrieveEventQuery.data?.event;
  const myGuest = event?.guests.find(
    (g: EventGuestType) =>
      g.invitee.type === "internal" && g.invitee.userId === self.userId,
  );

  const rsvp = React.useCallback(
    (rsvp: RSVPEnum) => {
      void rsvpMutation.call({
        eventUUID: props.action.action.eventUUID,
        rsvp,
        externalActionToken: null,
        repeatHandling: ActionRepeatContextEnum.this,
      });
    },
    [props.action.action.eventUUID, rsvpMutation],
  );

  return (
    <AnimateChangeInHeight>
      {event == null ? (
        <div />
      ) : (
        <div
          onClick={(evt) => evt.stopPropagation()}
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            paddingTop: 10,
            alignItems: "center",
          }}
        >
          RSVP:
          <RSVPButton
            onClick={() => rsvp(RSVPEnum.yes)}
            active={myGuest?.rsvp === RSVPEnum.yes}
          >
            Yes
          </RSVPButton>
          <RSVPButton
            onClick={() => rsvp(RSVPEnum.no)}
            active={myGuest?.rsvp === RSVPEnum.no}
          >
            No
          </RSVPButton>
          <RSVPButton
            onClick={() => rsvp(RSVPEnum.maybe)}
            active={myGuest?.rsvp === RSVPEnum.maybe}
          >
            Maybe
          </RSVPButton>
        </div>
      )}
    </AnimateChangeInHeight>
  );
});

export const AlertActions = React.memo(function AlertActions(props: {
  alert: AlertType;
  alertsCountQuery?: UseMoopsyQueryRetValAny;
  alertsQuery?: UseMoopsyQueryRetValAny;
}): JSX.Element | null {
  const markAlertsRead =
    seamlessClient.useMutation<MarkAlertsReadBP.Plug>(
      MarkAlertsReadBP,
      { querySideEffects: [props.alertsCountQuery ?? null, props.alertsQuery ?? null] },
    );
  const navigate = useNavigate();
  const { actions } = props.alert;
  const { current } = React.useContext(ContinuityMeetingContext);

  if (actions == null || actions.length === 0) return null;

  return (
    <div>
      {actions.map((action) =>
        action.format === "join-meeting" ? (
          <div
            key={action.format}
            style={{
              userSelect: "none",
              paddingTop: 10,
              color: current == null ? "#4caf50" : "#dddddd",
              cursor: "pointer",
            }}
            onClick={() => {
              void markAlertsRead.call({ alertUUIDs: [props.alert.uuid] });
              navigate(action.action.params.link);
            }}
          >
            <FontAwesomeIcon icon={faPhoneAlt} />
            &nbsp;
            {current == null ? "Join Meeting" : "Switch Meeting"}
          </div>
        ) : action.format === "rsvp" ? (
          <RSVPAction action={action} />
        ) : null,
      )}
    </div>
  );
});
