import { Editor, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { SlateElementWithType } from "../types";

export function placeCaretAfterElement(editor: ReactEditor, element: SlateElementWithType) {
  const elementPath = ReactEditor.findPath(editor, element);

  // @ts-expect-error --- 
  Transforms.select(editor, Editor.after(editor, Editor.end(editor, elementPath)));

  Transforms.collapse(editor, { edge: "end" });
}