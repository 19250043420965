import React from "react";
import { styled } from "@hiyllo/ux/styled";
import { FlexHeightGrower } from "@hiyllo/ux/common";

import * as GetTotalStorageUsedBP from "../../../blueprints/admin/storage/get-total-storage-used";
import * as GetFederationBP from "../../../blueprints/federation/get-federation";

import { getRootURL } from "../../../platform/environment/get-root-url";
import { ContinuityMeetingContext } from "../../../main/meeting-provider";
import { CollapsedVideoMeetingUI } from "../../meet/views/meeting-view";
import { useConfig } from "../../../platform/config/config-context";
import { useUploadingContext } from "@hiyllo/omni-user-files/main";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/pro-regular-svg-icons";
import { useSelf, useUndoables } from "@hiyllo/omni-continuity";
import { seamlessClient } from "../../../seamless-client";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { useTheme } from "@hiyllo/ux/theme";
import { MeetingTimer } from "./alerts/alert-event-card";
import { openWindow } from "../../../platform/open-window";

const LeftSidebarContainer = styled<"div">("div", ({ $isMobile, $theme }) => ({
  width: 280,
  height: "100%",
  background: $theme.background2,
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
  ...($isMobile && { flexGrow: 1 }),
}));

export const LeftSidebarLogo = styled("img", {
  width: "calc(50% - 40px)",
  padding: 20,
  maxHeight: 40,
  objectFit: "contain",
  objectPosition: "left center",
});

export const LeftSidebarTypography = styled("div", ({ $theme }) => ({
  width: "calc(50% - 40px)",
  padding: 20,
  maxHeight: 100,
  objectFit: "contain",
  fontFamily: '"hiyllo", sans-serif',
  fontSize: 20,
  color: $theme.foreground,
}));

const ContinuityMeetingTile = React.memo(
  function ContinuityMeetingTile(): JSX.Element | null {
    const { current } = React.useContext(ContinuityMeetingContext);

    if (current == null) {
      return null;
    }

    return (
      <div>
        <CollapsedVideoMeetingUI />
      </div>
    );
  },
);

const ActiveUploadIconContainer = styled("div", ({ $theme }) => ({
  background: $theme.buttonBackground,
  height: 50,
  width: 50,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  fontSize: 25,
  flexShrink: 0,
}));

const UploadContainer = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 10,
  paddingBottom: 10,
  gap: 20,
  alignItems: "center",
  fontFamily: "hiyllo",
  background: $theme.background3,
  color: $theme.foreground
}));

const StorageTile = React.memo(function StorageTile(): JSX.Element {
  const totalStorageUsedQuery =
    seamlessClient.useQuery<GetTotalStorageUsedBP.Plug>(
      GetTotalStorageUsedBP,
      null,
    );

  if (totalStorageUsedQuery.isError || totalStorageUsedQuery.isLoading) {
    return <div />;
  }

  const mb = totalStorageUsedQuery.data.bytes / 1000 / 1000;
  const gb = mb / 1000;

  const formatted = gb > 0.1 ? `${gb.toFixed(2)}GB` : `${Math.ceil(mb)}MB`;

  return (
    <div
      style={{
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 15,
        fontFamily: "hiyllo",
        color: "white"
      }}
    >
      <b>{formatted}</b> Storage Used
    </div>
  );
});

const RestrictedWarning = React.memo(function RestrictedWarning(): JSX.Element {
  const self = useSelf();
  const federation = seamlessClient.useQuery<GetFederationBP.Plug>(GetFederationBP, null);

  return (
    <div
      style={{
        background: "#c62828",
        padding: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
        }}
      >
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          style={{ color: "white" }}
        />
        <div>
          Your Hiyllo Work free trial has expired. Access will be restricted
          shortly, and read-only mode is active.
        </div>
      </div>
      {self.isAdmin ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 10,
            marginLeft: 20,
          }}
        >
          <div
            style={{
              background: "rgba(255, 255, 255, 0.25)",
              padding: 10,
              borderRadius: 10,
              userSelect: "none",
              cursor: "pointer",
            }}
            onClick={() => {
              openWindow(`https://${federation.data?.federation?.slug ?? ""}.hiyllo.business/billing`);
            }}
          >
            <div>Click here to fix</div>
          </div>
        </div>
      ) : null}
    </div>
  );
});

export const LeftSidebar = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const config = useConfig();
  const hasUFP = config.branding?.logo?.fileId != null;
  const { activeUploads } = useUploadingContext();
  const self = useSelf();
  const undoables = useUndoables();
  const $theme = useTheme();

  React.useEffect(() => {
    const preventUnload = (evt: Event): void => {
      evt.preventDefault();
      // @ts-expect-error we know its deprecated but its the only way it works
      return (event.returnValue = "You will be disconnected from your meeting");
    };

    if (activeUploads.length > 0) {
      window.addEventListener("beforeunload", preventUnload);

      return () => {
        window.removeEventListener("beforeunload", preventUnload);
      };
    }
  });

  return (
    <LeftSidebarContainer>
      <FlexHeightGrower style={{ overflowY: "auto", overflowX: "hidden" }}>
        {children}
      </FlexHeightGrower>
      <ContinuityMeetingTile />
      <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
        {activeUploads.length > 0 ? (
          <UploadContainer>
            <ActiveUploadIconContainer>
              <motion.div
                animate={{
                  translateY: ["100%", "-150%"],
                }}
                transition={{
                  repeat: Infinity,
                  duration: 0.5,
                  repeatDelay: 0.3,
                  ease: "linear",
                }}
              >
                <FontAwesomeIcon icon={faArrowUp} style={{ color: "black" }} />
              </motion.div>
            </ActiveUploadIconContainer>
            <div>
              <div style={{ fontSize: 16 }}>
                Uploading {activeUploads[0].name}:{" "}
                {Math.floor(activeUploads[0].progress * 100)}%
              </div>
              {activeUploads.length > 1 ? (
                <div style={{ fontSize: 12, marginTop: 2.5 }}>
                  {activeUploads.length - 1} more to upload after
                </div>
              ) : null}
            </div>
          </UploadContainer>
        ) : null}
        {undoables.map((undoable) => (
          <UploadContainer key={undoable.id}>
            <div style={{ width: "100%" }}>
              <div style={{ fontSize: 16, marginBottom: 5 }}>
                {undoable.label}
              </div>
              <div>
                <div
                  style={{
                    background: $theme.midground,
                    borderRadius: 5,
                    padding: 10,
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={undoable.fn}
                >
                  Undo (Available for{" "}
                  <MeetingTimer startDate={undoable.undoableTill} />)
                </div>
              </div>
            </div>
          </UploadContainer>
        ))}
      </div>
      {self.isAdmin ? <StorageTile /> : null}
      <div>
        {hasUFP || config.isSolo === true ? (
          <LeftSidebarLogo
            src={
              config.isSolo === true
                ? "/hiyllo-solo/icon/gradient-transparent.png"
                : getRootURL() + "/ufplogo.png"
            }
          />
        ) : (
          <LeftSidebarTypography>{config.platformName}</LeftSidebarTypography>
        )}
      </div>
      {config.restricted === true ? <RestrictedWarning /> : null}
    </LeftSidebarContainer>
  );
};
