import React from "react";
import { H1 } from "../../../../ux/alpha/typography";
import { Input } from "@hiyllo/ux/input";
import { styled } from "@hiyllo/ux/styled";
import { FormButton, Spacer } from "../../../../ux/alpha";
import { SprintStatusEnum } from "@hiyllo/omni-common/src/types/tasks/sprint";
import * as CreateSprintBP from "../../../../blueprints/tasks/sprints/create-sprint";
import * as GetSprintBP from "../../../../blueprints/tasks/sprints/get-sprint";
import { seamlessClient } from "../../../../seamless-client";
import { type UseMoopsyQueryRetValAny } from "@moopsyjs/react";
import { useNavigate } from "@hiyllo/omni-router";
import { Features } from "@hiyllo/omni-common/src/types/navigation/features";
import { LoadingSpinnerFullView } from "../../../../platform/loading/spinner-loading-full";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { useGetTeam } from "../../../organization/hooks/use-get-team";
import { type TeamType } from "@hiyllo/omni-common/src/types/organization/teams";
import { InfoCard } from "../../../../ux/info-card";

const Row = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 20,
});

const RolloverNotice = React.memo(function RolloverNotice(props: {
  sprintUUID: string;
}): JSX.Element {
  const sprintQuery = seamlessClient.useQuery(GetSprintBP, {
    sprintUUID: props.sprintUUID,
  });

  if (
    sprintQuery.isLoading ||
    sprintQuery.isError ||
    sprintQuery.data.sprint == null
  ) {
    return <LoadingSpinner />;
  }

  return (
    <div style={{ paddingBottom: 15 }}>
      Rolling over tasks from sprint: {sprintQuery.data.sprint.name}
    </div>
  );
});

export const CreateSprintView = React.memo(function CreateSprintView(props: {
  team: TeamType;
  teamSprintsQuery?: UseMoopsyQueryRetValAny | null;
  rolloverFromSprint: string | null;
  onCreated?: (sprintUUID: string) => void;
}): JSX.Element {
  const nameInputRef = React.useRef<HTMLInputElement>(null);
  const createSprintMutation =
    seamlessClient.useMutation<CreateSprintBP.Plug>(CreateSprintBP, {
      querySideEffects: [props.teamSprintsQuery ?? null],
    });

  const createSprint = React.useCallback(() => {
    if (nameInputRef.current == null) {
      return;
    }

    const name = nameInputRef.current.value;

    void createSprintMutation
      .call({
        teamUUID: props.team.uuid,
        name,
        status: SprintStatusEnum.new,
        timing: null,
        rolloverFromSprintUUID: props.rolloverFromSprint,
      })
      .then((res) => {
        props.onCreated?.(res.sprintUUID);
      });
  }, [createSprintMutation, props]);

  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between", gap: 40 }}>
      <div>
        <H1>Create sprint for {props.team.name}</H1>

        {props.rolloverFromSprint !== null ? (
          <RolloverNotice sprintUUID={props.rolloverFromSprint} />
        ) : null}

        <Input label="Sprint Name" ref={nameInputRef} />

        <Spacer height={30} />

        <Row>
          <FormButton
            label="Create Sprint"
            onClick={createSprint}
            isLoading={createSprintMutation.isLoading}
          />
        </Row>
      </div>
      <InfoCard
        title="Sprints"
        label="What are sprints"
        description={[
          "Sprints allow you to plan work for your team on a short time scale",
          "While they're typically used by agile engineering teams, sprints can be useful for any type of working group",
          "At the core, sprints are simply a period of time in which your team plans to complete a pre-determined set of tasks",
          "While not always recommended, you can add/remove tasks to a sprint at any time, even once it has started"
        ]}
      />
    </div>
  );
});

export const CreateSprintWrapper = React.memo(
  function CreateSprintWrapper(props: {
    teamUUID: string;
    rolloverFromSprint: string | null;
  }): JSX.Element {
    const groupQuery = useGetTeam({ teamUUID: props.teamUUID });
    const navigate = useNavigate();
    const onCreated = React.useCallback(
      (sprintUUID: string) => {
        navigate({
          feature: Features.tasks,
          params: {
            view: "sprint-planning",
            teamUUID: props.teamUUID,
            sprintUUID,
          },
        });
      },
      [navigate, props.teamUUID],
    );

    if (groupQuery.isLoading || groupQuery.isError) {
      return <LoadingSpinnerFullView />;
    }
    return (
      <CreateSprintView
        team={groupQuery.data.team}
        onCreated={onCreated}
        rolloverFromSprint={props.rolloverFromSprint}
      />
    );
  },
);
