/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { ContinuityAccessType } from "../../types/continuity/access";

export type ParamsType = {
  address: string;
  senderName: string;
  access: ContinuityAccessType;
};
export type ResponseType = {
  uuid: string;
};
export const Endpoint = "mail/create-mailbox";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 5,
  per: 1000,
};
export const paramsSchema = {"type":"object","properties":{"address":{"type":"string"},"senderName":{"type":"string"},"access":{"$ref":"#/definitions/ContinuityAccessType"}},"required":["access","address","senderName"],"definitions":{"ContinuityAccessType":{"type":"object","properties":{"teams":{"type":"array","items":{"type":"string"}},"users":{"type":"array","items":{"type":"string"}}},"required":["teams","users"]}},"$schema":"http://json-schema.org/draft-07/schema#"}