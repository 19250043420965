import React from "react";
import { seamlessAuth, useHasSavedLoginToken } from "../seamless-client";
import { LoadingPage } from "@hiyllo/ux/standard-loader";
import { setUserId } from "@hiyllo/stardate/main";
import { Electron } from "../platform/electron";

export const AuthenticationProvider = React.memo(
  function AuthenticationProvider(props: React.PropsWithChildren): JSX.Element {
    const authState = seamlessAuth.useAuthState();
    const isAuthenticated = authState !== null;
    const isLoggedInUser = useHasSavedLoginToken();
    const [
      initialAuthenticationEstablished,
      setInitialAuthenticationEstablished,
    ] = React.useState(Electron.isElectron);

    console.debug("<AuthenticationProvider>", { authState, isAuthenticated, isLoggedInUser, initialAuthenticationEstablished });

    React.useEffect(() => {
      if (isAuthenticated) {
        setInitialAuthenticationEstablished(true);
        setUserId(authState?.userId ?? "");
      }
    }, [authState?.userId, isAuthenticated]);

    if (
      !isAuthenticated &&
      isLoggedInUser &&
      !initialAuthenticationEstablished
    ) {
      return <LoadingPage />;
    }

    return props.children as JSX.Element;
  },
);
