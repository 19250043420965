/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { MailMessageSlim } from "../../types/mail/message/message-slim";

export type ParamsType = {
  domain: string;
};
export type ResponseType = void;
export const Endpoint = "mail/set-email-domain";
export const Method = "POST";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 2,
  per: 1500,
};
export const paramsSchema = {"type":"object","properties":{"domain":{"type":"string"}},"required":["domain"],"$schema":"http://json-schema.org/draft-07/schema#"}