/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { Mailbox } from "../../types/mail/mailbox";

export type ParamsType = null;
export type ResponseType = {
  mailboxes: Mailbox[];
};
export const Endpoint = "mail/list-mailboxes";
export const Method = "GET";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

export const RateLimitingConfig = {
  calls: 4,
  per: 1000,
};
export const paramsSchema = {"type":"null","$schema":"http://json-schema.org/draft-07/schema#"}