import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faBold, faItalic, faPalette, faTextSize, faUnderline } from "@fortawesome/pro-light-svg-icons";
import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { ReactEditor } from "slate-react";
import { BooleanMarkEnum, toggleBooleanMark } from "../marks/boolean-marks";
import fuzzysort from "fuzzysort";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Editor } from "slate";
import { type HiylloIcon } from "@hiyllo/icons";
import { useShowDialog } from "@hiyllo/ux/dialogs";

const Container = styled("div", ({ $theme }) => ({
  color: $theme.foreground,
  backgroundColor: "rgba(0,0,0,0.25)",
  backdropFilter: "blur(10px)",
  padding: 10,
  borderRadius: 5,
}));

const CommandPaletteInput = styled("input", ({ $theme }) => ({
  background: "transparent",
  backdropFilter: "blur(10px)",
  borderRadius: 5,
  height: 30,
  border: "1px rgba(255, 255, 255, 0.25) solid",
  outline: "none",
  width: 150,
  fontSize: 12.5,
  fontFamily: "hiyllo",
  color: $theme.foreground,
  padding: "0 10px",
  marginBottom: 10
}));

const CommandContainer = styled<"div", { $highlighted: boolean }>("div", ({ $theme, $highlighted }) => ({
  display: "flex",
  paddingLeft: 2.5,
  paddingRight: 2.5,
  borderRadius: 5,
  backgroundColor: $highlighted ? "rgba(255,255,255,0.1075" : "transparent",
  flexDirection: "row",
  alignItems: "center",
  gap: 7.5,
  height: 30,
}));

const CommandIcon = styled("div", ({ $theme }) => ({
  backgroundColor: "rgba(255,255,255,0.25)",
  backdropFilter: "blur(10px)",
  borderRadius: 5,
  height: 25,
  width: 25,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 12.5
}));

const CommandLabel = styled("div", ({ $theme }) => ({
  fontSize: 15,
  color: $theme.foreground,
}));

export type Command = {
  icon: { fa: IconDefinition } | HiylloIcon;
  label: string;
  action: (editor: ReactEditor) => void;
};

function useCommands(editor: ReactEditor, query: string): Command[] {
  const showDialog = useShowDialog();

  return React.useMemo(() => {
    const retval = [
      {
        icon: { fa: faBold },
        label: "Bold",
        action: () => {
          toggleBooleanMark(editor, BooleanMarkEnum.bold);
        }
      },
      {
        icon: { fa: faUnderline },
        label: "Underline",
        action: () => {
          toggleBooleanMark(editor, BooleanMarkEnum.underline);
        }
      },
      {
        icon: { fa: faItalic },
        label: "Italic",
        action: () => {
          toggleBooleanMark(editor, BooleanMarkEnum.italic);
        }
      },
      {
        icon: { fa: faTextSize },
        label: "Resize to 20px",
        action: () => {
          Editor.addMark(editor, "fontSize", 20);
        }
      },
      {
        icon: { fa: faTextSize },
        label: "Resize to 15px",
        action: () => {
          Editor.addMark(editor, "fontSize", 15);
        }
      },
      {
        icon: { fa: faTextSize },
        label: "Make it Tiny",
        action: () => {
          Editor.addMark(editor, "fontSize", 10);
        }
      },
      {
        icon: { fa: faTextSize },
        label: "Make it Huge",
        action: () => {
          Editor.addMark(editor, "fontSize", 40);
        }
      },
      {
        icon: { fa: faTextSize },
        label: "Header 1",
        action: () => {
          Editor.addMark(editor, "fontSize", 30);
          toggleBooleanMark(editor, BooleanMarkEnum.bold, true);
          toggleBooleanMark(editor, BooleanMarkEnum.italic, false);
          toggleBooleanMark(editor, BooleanMarkEnum.underline, false);
        }
      },
      {
        icon: { fa: faPalette },
        label: "Text Color: Red",
        action: () => {
          Editor.addMark(editor, "color", "#ff0000");
        }
      },
      {
        icon: { fa: faPalette },
        label: "Text Color: Green",
        action: () => {
          Editor.addMark(editor, "color", "#00ff00");
        }
      },
      {
        icon: { fa: faPalette },
        label: "Text Color: Blue",
        action: () => {
          Editor.addMark(editor, "color", "#0000ff");
        }
      },
      {
        icon: { fa: faPalette },
        label: "Text Color: Default",
        action: () => {
          Editor.addMark(editor, "color", "");
        }
      },
    ];

    if (!isNaN(Number(query))) {
      const label = `Resize to ${query}px`;

      const existing = retval.find((command) => command.label === label);

      if (existing == null) {
        retval.push({
          icon: { fa: faTextSize },
          label: label,
          action: () => {
            Editor.addMark(editor, "fontSize", Number(query));
          }
        });
      }
    }

    return retval;
  }, [editor, query]);
}

export const CommandPalette = React.memo(function CustomCommandPalette(props: {
  editor: ReactEditor;
  onClose: () => void;
  customCommands?: Command[];
}): JSX.Element {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [query, setQuery] = React.useState("");
  const commands = useCommands(props.editor, query);
  const [index, setIndex] = React.useState(0);

  const filteredCommands = React.useMemo(() => {
    const filteredCommands: Command[] = query === "" ? commands : fuzzysort.go(query, commands, {
      keys: ["label"],
    }).map((result) => result.obj);

    return filteredCommands.slice(0, 5);
  }, [commands, query]);

  React.useEffect(() => {
    const previousSelection = props.editor.selection;
    inputRef.current?.focus();
    return () => {
      ReactEditor.focus(props.editor);
      props.editor.selection = previousSelection;
    };
  }, []);

  const mountedRef = React.useRef(false);
  React.useEffect(() => {
    window.requestAnimationFrame(() => {
      mountedRef.current = true;
    });
  }, []);

  React.useEffect(() => {
    setIndex(0);
  }, [query]);

  return (
    <div>
      <Container>
        <div style={{ fontSize: 10, opacity: 0.5, paddingBottom: 5 }}>
          Command Palette - ESC to Cancel
        </div>
        <CommandPaletteInput
          _ref={inputRef}
          value={query}
          onChange={(event: React.KeyboardEvent<HTMLInputElement>) => setQuery(event.currentTarget.value)}
          onBlur={() => {
            if (mountedRef.current) {
              props.onClose();
            }
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Escape") {
              event.stopPropagation();
              props.onClose();
            }
            if (event.key === "Enter") {
              event.preventDefault();
              event.stopPropagation();
              props.onClose();
              window.setTimeout(() => {
                filteredCommands[index]?.action(props.editor);
              }, 50);
            }
            if (event.key === "ArrowDown") {
              setIndex((index + 1) % filteredCommands.length);
            }
            if (event.key === "ArrowUp") {
              setIndex((index - 1 + filteredCommands.length) % filteredCommands.length);
            }
          }}
        />
        {filteredCommands.map((command, i) => (
          <CommandContainer key={command.label} $highlighted={i === index}>
            <CommandIcon>
              {'fa' in command.icon ? <FontAwesomeIcon icon={command.icon.fa} /> : <command.icon width={15} color="white" />}
            </CommandIcon>
            <CommandLabel>{command.label}</CommandLabel>
          </CommandContainer>
        ))}
      </Container>
    </div>
  );
});