import React from "react";

export { Input } from "./input";
export { Form, FormButton } from "./form";
export { PillStyleButton } from "./buttons";
export {
  SpaceBetweenRowLayout,
  VerticalLayout,
  HorizontalLayout,
  AssertWidth,
  Spacer,
} from "./layouts";
export { useOnClickOut } from "./utils";

export const Centered = React.memo(function Centered(
  props: React.PropsWithChildren<{ style?: React.CSSProperties }>,
): JSX.Element {
  return (
    <div
      {...props}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...props.style,
      }}
    />
  );
});
