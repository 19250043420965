import React from "react";
import { faBell, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type AlertLinkType } from "../../../types/alerts/alert";
import { motion, AnimatePresence } from "framer-motion";
import { useOnClickOutV2 } from "../../../ux/alpha/utils";
import { useAlertsContext } from "@hiyllo/omni-alerts";
import { AlertsPillContainer } from "./alerts/pill-components";
import { AlertsWindow } from "./alerts/alerts-window";
import { type useNavigate } from "@hiyllo/omni-router";
import { openWindow } from "../../../platform/open-window";
import { MouseInteraction } from "@hiyllo/ux/animation";

export function navigateToAlertLink(
  eNav: ReturnType<typeof useNavigate>,
  link: AlertLinkType,
): void {
  if ("href" in link) {
    openWindow(link.href);
  } else {
    eNav(link.enav);
  }
}

export const AlertsPill = React.memo(function AlertsPill(): JSX.Element {
  const [windowIsOpen, setWindowIsOpen] = React.useState(false);
  const clickOutSafeAreaRef = useOnClickOutV2(() => setWindowIsOpen(false));
  const { count, alertsCountQuery } = useAlertsContext();
  const [pillHovered, setPillHovered] = React.useState(false);

  return (
    <div style={{ position: "relative" }}>
      <AlertsPillContainer
        _ref={clickOutSafeAreaRef}
        isActive={count !== 0}
        onClick={() => {
          setWindowIsOpen((v) => !v);
        }}
        onMouseEnter={() => setPillHovered(true)}
        onMouseLeave={() => setPillHovered(false)}
        animate={
          windowIsOpen
            ? {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              paddingRight: 15,
              marginLeft: 15,
              transition: { duration: 0.01 },
            }
            : {
              borderTopRightRadius: 15,
              borderBottomRightRadius: 15,
              transition: { delay: 0.1 },
            }
        }
      >
        <div
          style={{ width: 50, height: "100%", overflow: "hidden" }}
        >

          <motion.div
            style={{
              width: 100,
              height: "100%",
              overflow: "hidden",
              display: "flex",
              flexDirection: "row",
            }}
            animate={{
              x: windowIsOpen ? 0 : -50,
            }}
            transition={{
              duration: 0.3,
              ease: [0.1, 0.12, 0.1, 0.95]
            }}
            initial={{
              x: -50,
            }}
          >
            <div style={{ width: 50, height: "100%", flexShrink: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
              <FontAwesomeIcon icon={faTimes} fixedWidth />
            </div>
            <div style={{ width: 50, height: "100%", flexShrink: 0, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
              <motion.div animate={{ rotate: pillHovered ? "-15deg" : "0deg" }}>
                <FontAwesomeIcon icon={faBell} style={{ fontSize: "0.9em" }} />
              </motion.div>
              <motion.div
                animate={{ width: count === 0 ? 0 : "" }}
                initial={{ width: count === 0 ? 0 : "" }}
                style={{ overflow: "hidden" }}
              >
                <div>&nbsp;{count}</div>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </AlertsPillContainer>
      <AnimatePresence>
        {windowIsOpen ? (
          <AlertsWindow
            alertsCountQuery={alertsCountQuery}
            onClose={() => {
              setWindowIsOpen(false);
            }}
          />
        ) : null}
      </AnimatePresence>
    </div>
  );
});
