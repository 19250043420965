import React from "react";
import {
  RSVPEnum,
  type CalendarEvent,
  EventTypeEnum,
  type EventTimingWithComputed,
} from "@hiyllo/omni-common/src/types/calendar/calendar-event";
import { useHourHeight } from "../hooks/use-hour-height";
import { DayWidthContext, RetrieveEventsQueryContext } from "../contexts";
import { useOverlapIndex } from "../hooks/use-overlap-index";
import { DatePositioned, DraggedEventContext } from "./date-positioned";
import "moment";
import moment from "moment-timezone";
import { type PositioningDate, type dayToRowPosition } from "../consts";
import { useSelf } from "@hiyllo/omni-continuity";
import { styled } from "@hiyllo/ux/styled";
import { usePath } from "@hiyllo/omni-router";
import * as ChangeEventTimingBP from "../../../../../blueprints/calendar/change-event-timing";
import { formatEventTime } from "../../utils/format-event-time";
import { seamlessClient } from "../../../../../seamless-client";
import { ActionRepeatContextEnum } from "@hiyllo/omni-common/src/types/calendar/repeating";
import { useTimezone } from "../hooks/use-timezone";
import { CircleButton } from "@hiyllo/ux/circle-button";
import {
  faArrowDown,
  faArrowUp,
  faCheck,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { useOnClickOutV2 } from "../../../../../ux/alpha/utils";

const EventTileElement = styled<
  "div",
  {
    tileColor: null | string;
    myRSVP: RSVPEnum | null;
    pastEvent: boolean;
    amOptional?: boolean;
    proposingDate?: boolean;
  }
>(
  "div",
  ({ $theme, tileColor, myRSVP, pastEvent, amOptional, proposingDate }) => ({
    borderColor: tileColor ?? "#2196f399",
    borderWidth: amOptional === true && myRSVP != null ? 0 : 1,
    padding: amOptional === true ? 1 : 0,
    borderStyle: amOptional ? "dotted" : "solid",
    background:
      myRSVP == null
        ? "transparent"
        : amOptional
          ? `repeating-linear-gradient(
    45deg,
    #2196f366,
    #2196f366 10px,
    transparent 10px,
    transparent 20px
  )`
          : (tileColor ?? "#2196f3") + (myRSVP === RSVPEnum.yes ? "cc" : "44"),
    opacity: pastEvent ? 0.5 : 1,
    cursor: "pointer",
    margin: 1,
    height: "calc(100% - 6px)",
    borderRadius: 2.5,
    backdropFilter: "blur(2.5px)",
    overflow: "hidden",
    boxShadow: proposingDate ? "rgba(0, 0, 0, 0.5) 0px 0px 10px 5px" : "",
  }),
);

const ProposalPopover = styled<"div", { day: number; dayWidth: number }>(
  "div",
  ({ $theme, day, dayWidth }) => ({
    backgroundColor: $theme.midground,
    padding: 8,
    position: "absolute",
    top: 0,
    [day <= 3 && day !== 0 ? "left" : "right"]: dayWidth + 1,
    zIndex: 10,
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px 5px",
    borderRadius: 8,
    whiteSpace: "nowrap",
  }),
);

const EventTileContainer = styled<
  "div",
  {
    overlapsWithBusyStrip?: boolean;
    left?: number;
    duration: number;
    minuteHeight: number;
    tileWidth: number;
    overlapIndex: number;
  }
>(
  "div",
  ({
    $theme,
    duration,
    minuteHeight,
    tileWidth,
    overlapIndex,
    left,
    overlapsWithBusyStrip,
  }) => {
    const determinedLeft: number = left ?? tileWidth * overlapIndex;

    const styles: React.CSSProperties = {
      height: minuteHeight * duration - 1,
      width:
        tileWidth -
        0.5 -
        (overlapsWithBusyStrip && determinedLeft === 0 ? 15 : 0),
      left:
        determinedLeft +
        (overlapsWithBusyStrip && determinedLeft === 0 ? 15 : 0),
      position: "absolute",
    };

    return styles;
  },
);

const EventTileInner = styled<
  "div",
  { durationInMinutes: number; fontSize: number, lineHeight: string }
>("div", ({ durationInMinutes, fontSize, lineHeight }) => ({
  padding: durationInMinutes >= 60 ? 4 : durationInMinutes >= 30 ? 2 : 0,
  fontSize,
  overflowWrap: "break-word",
  textOverflow: "ellipsis",
  overflow: "hidden",
  lineHeight
}));

const EventTimingText = styled("div", {
  fontSize: 10,
  opacity: 0.75,
});

export const EventTile = React.memo(function EventTile({
  event,
  onClick,
  overlapsWith,
  overrideDay,
  color,
  width,
  left,
}: {
  width?: number;
  left?: number;
  color?: string | null;
  overrideDay?: keyof typeof dayToRowPosition;
  event: CalendarEvent;
  onClick: () => void;
  overlapsWith: CalendarEvent[];
}): JSX.Element | null {
  const timezone = useTimezone();
  const retrieveEventsQuery = React.useContext(RetrieveEventsQueryContext);
  const hourHeight = useHourHeight();
  const minuteHeight = hourHeight / 60;
  const dayWidth = React.useContext(DayWidthContext);
  const eventHeight = minuteHeight * event.timing.durationInMinutes;
  const fontSize = Math.min(12, Math.max(7.25, Math.min(14, (eventHeight - 10) * 0.5)));
  const lineHeight = Math.min(1, Math.max(1, Math.min(14, (eventHeight - 10) * 0.25))) + 'em';
  const tileWidth = dayWidth / (overlapsWith.length + 1);
  const overlapIndex = useOverlapIndex(event, overlapsWith);
  const self = useSelf();
  const myGuest = event.guests.find(
    (g) => g.invitee.type === "internal" && g.invitee.userId === self.userId,
  );
  const myRSVP = myGuest?.rsvp ?? null;
  const params = usePath().params;
  const datePositionedRef = React.useRef<HTMLDivElement>(null);

  const firstTileDuration =
    (event.timing._computed.end.valueOf() -
      event.timing._computed.start.valueOf()) /
    60000;

  const changeEventTimingMutation =
    seamlessClient.useMutation<ChangeEventTimingBP.Plug>(
      ChangeEventTimingBP,
      { querySideEffects: [retrieveEventsQuery] },
    );

  const [proposedDate, setProposedDate] =
    React.useState<PositioningDate | null>(null);

  const proposedDateRaw = React.useMemo(() => {
    if (proposedDate == null) {
      return null;
    }

    const start = moment(event.timing._computed.start);

    const desiredDayOfWeek = proposedDate.day;

    const newDate = start.clone();

    if (newDate.day() === 0) {
      newDate.subtract(1, "week");
    }

    if (desiredDayOfWeek === 0) {
      newDate.day(desiredDayOfWeek).add(1, "week");
    } else {
      newDate.day(desiredDayOfWeek);
    }

    newDate.hours(proposedDate.hours);
    newDate.minutes(proposedDate.minutes);

    return newDate.toDate();
  }, [event.timing._computed.start, proposedDate]);

  const updateDate = React.useCallback(
    (date: PositioningDate): void => {
      setProposedDate(null);

      const start = moment(event.timing._computed.start);
      const desiredDayOfWeek = date.day;
      const newDate = start.clone();

      if (newDate.day() === 0) {
        newDate.subtract(1, "week");
      }

      if (desiredDayOfWeek === 0) {
        newDate.day(desiredDayOfWeek).add(1, "week");
      } else {
        newDate.day(desiredDayOfWeek);
      }

      newDate.hours(date.hours);
      newDate.minutes(date.minutes);

      const newTiming: EventTimingWithComputed = {
        date: newDate.format("YYYYMMDD"),
        allDay: false,
        start: {
          hours: date.hours,
          minutes: date.minutes,
          timezone: moment.tz.guess(),
        },
        durationInMinutes: event.timing.durationInMinutes,
        _computed: {
          start: newDate.toDate(),
          end: newDate
            .clone()
            .add(event.timing.durationInMinutes, "minutes")
            .toDate(),
        },
      };

      void changeEventTimingMutation.call({
        eventUUID: event.uuid,
        timing: newTiming,
        repeatHandling: ActionRepeatContextEnum.this,
      });
    },
    [
      changeEventTimingMutation,
      event.timing._computed.start,
      event.timing.durationInMinutes,
      event.uuid,
    ],
  );

  React.useEffect(() => {
    if (
      params != null &&
      "scrollToEvent" in params &&
      params.scrollToEvent === event.uuid &&
      datePositionedRef.current != null
    ) {
      datePositionedRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [event.uuid, params]);

  // if (myRSVP === RSVPEnum.no) {
  //   return null;
  // }

  const overlapsWithBusyStrip = overlapsWith.some(
    (e) => e.type === EventTypeEnum.busy,
  );

  const dbPosDate: PositioningDate = React.useMemo(
    () => ({
      day:
        overrideDay ??
        (moment(event.timing._computed.start)
          .tz(timezone)
          .day() as keyof typeof dayToRowPosition),
      hours: moment(event.timing._computed.start).tz(timezone).hours(),
      minutes: moment(event.timing._computed.start).tz(timezone).minutes(),
    }),
    [event.timing._computed.start, overrideDay, timezone],
  );

  const date = proposedDate ?? dbPosDate;

  const clickOutRef = useOnClickOutV2(() => {
    setProposedDate(null);
  });

  if (event.type === EventTypeEnum.busy) {
    return (
      <DatePositioned
        innerRef={datePositionedRef}
        key={event.uuid}
        date={{
          day:
            overrideDay ??
            (moment(event.timing._computed.start)
              .tz(timezone)
              .day() as keyof typeof dayToRowPosition),
          hours: moment(event.timing._computed.start).tz(timezone).hours(),
          minutes: moment(event.timing._computed.start).tz(timezone).minutes(),
        }}
      >
        <div
          style={{
            background: color ?? "#2196f388",
            minHeight: minuteHeight * 15,
            maxHeight: minuteHeight * firstTileDuration,
            marginLeft: 11,
            fontSize: 12,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: 0,
            paddingRight: 10,
            cursor: "pointer",
            maxWidth: dayWidth - 20,
          }}
          onClick={onClick}
          onMouseDown={(evt: React.SyntheticEvent) => evt.stopPropagation()}
        >
          <span style={{ pointerEvents: "none" }}>{event.title}</span>
        </div>
        <div
          style={{
            position: "absolute",
            top: 0,
            height: minuteHeight * firstTileDuration - 2,
            width: 10,
            marginLeft: 1,
            background: color ?? "#2196f388",
            cursor: "pointer",
          }}
          onClick={onClick}
          onMouseDown={(evt: React.SyntheticEvent) => evt.stopPropagation()}
        />
      </DatePositioned>
    );
  }

  return (
    <>
      <DatePositioned
        innerRef={datePositionedRef}
        key={event.uuid}
        date={date}
        onClick={onClick}
        duration={firstTileDuration}
        draggable={true}
        onNewDate={(date) => setProposedDate(date)}
      >
        <DraggedEventContext.Consumer>
          {(overridePosition) => (
            <EventTileContainer
              _ref={clickOutRef}
              overlapsWithBusyStrip={overlapsWithBusyStrip}
              duration={firstTileDuration}
              minuteHeight={minuteHeight}
              tileWidth={width ?? tileWidth}
              left={left}
              overlapIndex={overlapIndex}
              onMouseDown={(evt: React.SyntheticEvent) => evt.stopPropagation()}
            >
              {proposedDate != null ? (
                <ProposalPopover day={date.day} dayWidth={dayWidth}>
                  <div
                    style={{ position: "relative" }}
                    onClick={(evt) => evt.stopPropagation()}
                  >
                    <div style={{ position: "absolute", top: -24, left: -24 }}>
                      <CircleButton
                        icon={faArrowUp}
                        size={30}
                        onClick={() =>
                          setProposedDate((p) =>
                            p == null
                              ? p
                              : p.minutes < 15
                                ? {
                                  ...p,
                                  hours: p.hours - 1,
                                  minutes: 60 + (p.minutes - 15),
                                }
                                : {
                                  ...p,
                                  minutes: p.minutes - 15,
                                },
                          )
                        }
                      />
                    </div>
                    <div style={{ paddingTop: 16, paddingBottom: 16 }}>
                      <div
                        style={{
                          fontFamily: "hiyllo",
                          fontWeight: "bold",
                          fontSize: 24,
                        }}
                      >
                        {moment(proposedDateRaw).format("h:mm a")}
                      </div>
                      <div style={{ fontFamily: "hiyllo", marginTop: 4 }}>
                        {moment(proposedDateRaw).format("dddd MMM Do")}
                      </div>
                      <div
                        style={{
                          marginTop: 6,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 8,
                        }}
                      >
                        <CircleButton
                          icon={faTimes}
                          onClick={() => setProposedDate(null)}
                          size={30}
                        />
                        <CircleButton
                          icon={faCheck}
                          onClick={() => updateDate(proposedDate)}
                          size={30}
                        />
                      </div>
                    </div>
                    <div
                      style={{ position: "absolute", bottom: -24, left: -24 }}
                    >
                      <CircleButton
                        icon={faArrowDown}
                        size={30}
                        onClick={() =>
                          setProposedDate((p) =>
                            p == null
                              ? p
                              : p.minutes >= 45
                                ? {
                                  ...p,
                                  hours: p.hours + 1,
                                  minutes: 0,
                                }
                                : {
                                  ...p,
                                  minutes: p.minutes + 15,
                                },
                          )
                        }
                      />
                    </div>
                  </div>
                </ProposalPopover>
              ) : null}
              <EventTileElement
                tileColor={color ?? null}
                myRSVP={myRSVP}
                pastEvent={event.timing._computed.end.valueOf() < Date.now()}
                amOptional={myGuest?.optional === true}
                proposingDate={proposedDate != null}
                className="EventTile"
              >
                <EventTileInner
                  durationInMinutes={firstTileDuration}
                  fontSize={fontSize}
                  lineHeight={lineHeight}
                >
                  {event.type !== EventTypeEnum.travel ? <div className="EventTile-Title">{event.title}</div> : null}
                  {proposedDate != null ? null : overridePosition == null ? (
                    event.type === EventTypeEnum.travel ? (
                      <EventTimingText>
                        {event.timing.durationInMinutes} minutes travel time
                      </EventTimingText>
                    ) : (
                      <EventTimingText>
                        {moment(event.timing._computed.start)
                          .tz(timezone)
                          .format("h:mm a")}{" "}
                        -{" "}
                        {formatEventTime(event.timing._computed.end, timezone)}
                      </EventTimingText>
                    )
                  ) : (
                    <EventTimingText>
                      {moment(event.timing._computed.start)
                        .day(overridePosition.day)
                        .hours(overridePosition.hours)
                        .minutes(overridePosition.minutes)
                        .format("h:mm a")}
                    </EventTimingText>
                  )}
                </EventTileInner>
              </EventTileElement>
            </EventTileContainer>
          )}
        </DraggedEventContext.Consumer>
      </DatePositioned>
    </>
  );
});
