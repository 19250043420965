import React from 'react';
import { Editor } from "slate";
import { HiylloImageElement } from "./image";
import { RenderElementProps } from "slate-react";
import { HiylloList } from './list';
import { Collapse } from './collapse';

const customElements: Record<string, {
  Component: React.NamedExoticComponent<any>;
  isVoid?: boolean;
}> = {
  "hiylloImage": {
    Component: HiylloImageElement,
    isVoid: true
  },
  "hiyllolist": {
    Component: HiylloList,
    isVoid: true
  },
  "collapse": {
    Component: Collapse
  }
};

function isHiylloVoid(type: string): boolean {
  return type in customElements && customElements[type].isVoid === true;
}

export const withEmbeds = (editor: Editor) => {
  const { isVoid } = editor;
  editor.isVoid = (element) => {
    // @ts-expect-error ---
    return isHiylloVoid(element.type) || isVoid(element);
  };
  return editor;
};

export const Element = (props: RenderElementProps) => {
  const { attributes, children } = props;
  const element = props.element as typeof props.element & { type: string };

  if (element.type in customElements) {
    const CustomElement = customElements[(element as any).type].Component;
    return <CustomElement {...attributes} {...element} element={element}>{children}</CustomElement>;
  }

  if (element.type === "bulleted-list") {
    return (
      <ul {...attributes}>
        {children}
      </ul>
    );
  }

  if (element.type === "list-item") {
    return (
      <li {...attributes}>
        {children}
      </li>
    );
  }

  return <div {...attributes}>{children}</div>;
};