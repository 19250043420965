import React from "react";
import { Editor, Text } from "slate";
import { EditorEmitter } from "../editor-v2";

export function useEditorMarks(editor: Editor, emitter: EditorEmitter) {
  const [marks, setMarks] = React.useState<Omit<Text, "text"> | null>(null);

  React.useEffect(() => {
    emitter.on("change", () => {
      setMarks(
        Editor.marks(editor)
      );
    });
  }, [editor, emitter]);

  return marks;
}