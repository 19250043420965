import React from "react";
import * as ListMailboxesBP from "../../../blueprints/mail/list-mailboxes";
import * as CreateMailboxBP from "../../../blueprints/mail/create-mailbox";
import * as DeleteMailboxBP from "../../../blueprints/mail/delete-mailbox";
import { seamlessClient } from "../../../seamless-client";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { useConfig } from "../../../platform/config/config-context";
import { useTheme } from "@hiyllo/ux/theme";
import { Mailbox, MailboxTypeEnum } from "../../../types/mail/mailbox";
import { type ContinuityAccessType } from "../../../types/continuity/access";
import { Input } from "@hiyllo/ux/input";
import { useSelf } from "@hiyllo/omni-continuity/main";
import { type UseMoopsyQueryRetValAny } from "@moopsyjs/react";
import { Button } from "@hiyllo/ux/button";
import { Typography } from "@hiyllo/ux/typography";
import { DangerButton } from "@hiyllo/ux/button";
import { RWAccessControlView } from "../../continuity/access/access-control-view";
import { InfoCard } from "../../../ux/info-card";
import { useShowAlert } from "@hiyllo/ux/dialogs";
import { MoopsyError } from "@moopsyjs/core/main";

const CreateMailboxPanel = React.memo(function CreateMailboxPanel(props: {
  mailboxesQuery: UseMoopsyQueryRetValAny;
}): JSX.Element {
  const self = useSelf();
  const $theme = useTheme();
  const [access, setAccess] = React.useState<ContinuityAccessType>({
    users: [self.userId],
    teams: [],
  });
  const [username, setUsername] = React.useState("");
  const [senderName, setSenderName] = React.useState("");
  const createMailboxMutation =
    seamlessClient.useMutation<CreateMailboxBP.Plug>(CreateMailboxBP, {
      querySideEffects: [props.mailboxesQuery],
    });
  const showAlert = useShowAlert();

  const onSubmit = React.useCallback(() => {
    createMailboxMutation
      .call({
        address: username + "@",
        senderName,
        access,
      })
      .then(() => {
        setUsername("");
        setSenderName("");
        setAccess({ users: [self.userId], teams: [] });
      }).catch((err) => {
        void showAlert({
          title: "Unable to create mailbox",
          message: (err as MoopsyError).description
        });
      });
  }, [access, createMailboxMutation, self.userId, senderName, showAlert, username]);

  return (
    <div
      style={{
        background: $theme.background2,
        padding: 15,
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
        gap: 15,
      }}
    >
      <Typography.SubHeader>Create Shared Mailbox</Typography.SubHeader>
      <Input
        label="Username (Part that comes before the @)"
        value={username}
        onChangeValue={setUsername}
      />
      <Input
        label="Sender Name (Name that is shown when you send an email)"
        value={senderName}
        onChangeValue={setSenderName}
      />
      <RWAccessControlView
        rwAccess={access}
        onChangeValue={setAccess}
        label="Read / Send Access"
      />
      <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
        <Button
          isLoading={createMailboxMutation.isLoading}
          onClick={onSubmit}
          label="Create Mailbox"
        />
      </div>
    </div>
  );
});

const MailboxView = React.memo(function MailboxView({
  mailbox,
  listMailboxesQuery,
}: {
  mailbox: Mailbox;
  listMailboxesQuery: UseMoopsyQueryRetValAny;
}): JSX.Element {
  const $theme = useTheme();
  const config = useConfig();
  const deleteMailboxesMutation =
    seamlessClient.useMutation<DeleteMailboxBP.Plug>(DeleteMailboxBP, {
      querySideEffects: [listMailboxesQuery],
    });

  return (
    <div
      key={mailbox.uuid}
      style={{
        background: $theme.background2,
        padding: 10,
        borderRadius: 5,
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            background: $theme.midground,
            padding: 5,
            borderRadius: 5,
            lineHeight: "1em",
          }}
        >
          {mailbox.type === MailboxTypeEnum.shared ? "Shared" : "Personal"}
        </div>
      </div>
      <div style={{ height: 5 }} />
      <div>
        {mailbox.address}
        {config.emailDomain}
      </div>
      <div style={{ height: 5 }} />
      {mailbox.type === MailboxTypeEnum.shared ? <div>
        <DangerButton
          label="Delete Mailbox"
          onClick={() => {
            void deleteMailboxesMutation.call({
              mailboxUUID: mailbox.uuid,
            });
          }}
          isLoading={deleteMailboxesMutation.isLoading}
        />
      </div> : null}
    </div>
  );
});

export const MailboxesView = React.memo(function MailboxesView(): JSX.Element {
  const listMailboxesQuery =
    seamlessClient.useQuery<ListMailboxesBP.Plug>(
      ListMailboxesBP,
      null,
    );

  if (listMailboxesQuery.isError) {
    return <div>Error</div>;
  }

  if (listMailboxesQuery.isLoading) {
    return <LoadingSpinnerFullView />;
  }

  return (
    <div style={{ padding: 20, display: "flex", flexDirection: 'row', gap: 20 }}>
      <div style={{ display: "flex", flexDirection: "column", gap: 10, flexGrow: 1 }}>
        <CreateMailboxPanel mailboxesQuery={listMailboxesQuery} />
        {listMailboxesQuery.data.mailboxes.map((mailbox) => (
          <MailboxView
            mailbox={mailbox}
            key={mailbox.uuid}
            listMailboxesQuery={listMailboxesQuery}
          />
        ))}
      </div>
      <div style={{ flexGrow: 0 }}>
        <InfoCard
          title="Shared Mailboxes"
          description={[
            "Shared Mailboxes are shared inboxes that can be accessed by multiple users. You can create a mailbox and give access to multiple users to read and send emails.",
            "There's also your personal mailbox, based on your account username, that only you can access."
          ]}
          label="What are mailboxes?"
        />
      </div>
    </div>
  );
});
