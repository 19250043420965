import { Editor } from "slate";
import { ReactEditor } from "slate-react";

export enum BooleanMarkEnum {
  bold = "bold",
  italic = "italic",
  underline = "underline",
}

export function toggleBooleanMark(editor: ReactEditor, mark: BooleanMarkEnum, setValue?: boolean) {
  const currentMarks: Record<string, boolean> = Editor.marks(editor) as Record<string, boolean>;
  const hasMark = currentMarks[mark] === true;

  if (setValue !== undefined && hasMark === setValue) {
    return;
  }

  if (hasMark) {
    Editor.removeMark(editor, mark);
  }
  else {
    Editor.addMark(editor, mark, true);
  }
}