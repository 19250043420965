import React from "react";

import { SelfProvider as SP, useSelfMaybe } from "@hiyllo/omni-continuity";
import { seamlessAuth, useHasSavedLoginToken } from "../seamless-client";
import { LoadingPage } from "@hiyllo/ux/standard-loader";

const SelfGate = React.memo(function SelfGate(props: React.PropsWithChildren): React.ReactNode {
  const hasSavedLoginToken = useHasSavedLoginToken();
  const maybeSelf = useSelfMaybe();

  if (hasSavedLoginToken && maybeSelf == null) {
    return (
      <LoadingPage />
    );
  }

  return props.children;
});

export const SelfProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  console.debug("<SelfProvider>");
  const hasSavedLoginToken = useHasSavedLoginToken();

  return (
    <SP
      authExtension={seamlessAuth}
      hasSavedLoginToken={hasSavedLoginToken}
      LoadingComponent={LoadingPage}
      loginOptional
    >
      <SelfGate>
        {children}
      </SelfGate>
    </SP>
  );
};
