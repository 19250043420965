import React from "react";
import { JoinHiylloMeetButton, Row } from "./components";
import {
  EventTypeEnum,
  type CalendarEvent,
} from "@hiyllo/omni-common/src/types/calendar/calendar-event";
import * as CreateSpectatorLinkBP from "../../../../blueprints/calendar/create-event-spectator-link";
import { CopyableLinkBox } from "../../../../ux/copyable-link-box";
import { Features } from "@hiyllo/omni-common/src/types/navigation/features";
import { type UseMoopsyQueryRetValAny } from "@moopsyjs/react";
import { useNavigate } from "@hiyllo/omni-router";
import { useIsSolo } from "../../../../platform/hooks/use-is-solo";
import { getRootURL } from "../../../../platform/environment/get-root-url";
import { VideoMeetingIcon } from "@hiyllo/icons/main";
import { Button, ButtonVariant } from "@hiyllo/ux/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScreenUsers } from "@fortawesome/pro-light-svg-icons";
import { Dialog } from "@hiyllo/ux/dialogs";
import { seamlessClient } from "../../../../seamless-client";

export const MeetingCard = React.memo(function MeetingCard({
  event,
  retrieveEventQuery,
}: {
  event: CalendarEvent;
  retrieveEventQuery: UseMoopsyQueryRetValAny;
}): JSX.Element {
  const navigate = useNavigate();
  const [showCreatorSpectatorLink, setShowCreatorSpectatorLink] = React.useState(false);
  const createSpectatorLinkMutation = seamlessClient.useMutation<CreateSpectatorLinkBP.Plug>(CreateSpectatorLinkBP, { querySideEffects: [retrieveEventQuery] });

  const onClickJoinMeeting = React.useCallback(() => {
    if (retrieveEventQuery.data?.event?.videoMeeting != null) {
      navigate({
        feature: Features.meet,
        params: {
          view: "event",
          eventUUID: event.uuid,
          password: retrieveEventQuery.data.event.videoMeeting.meetingPassword,
        },
      });
    }
  }, [event.uuid, navigate, retrieveEventQuery.data.event.videoMeeting]);
  const isSolo = useIsSolo();

  return (
    <>
      {showCreatorSpectatorLink ?
        <Dialog
          title="Create Spectator Link"
          message="Spectator links can be shared externally to allow someone to watch a meeting in progress without being able to speak or be seen."
          id="create-spectator-link"
          zIndex={100}
          buttons={event.spectatorLink != null ? [
            { type: "primary", label: "Close", onClick: "submit" }
          ] : [
            { type: "primary", onClick: "submit", label: "Create" },
            { type: "secondary", label: "Cancel", onClick: () => { } },
          ]}
          extras={
            event.spectatorLink != null ?
              <CopyableLinkBox
                label="Spectator Link"
                link={`${getRootURL()}/m/s/${event.spectatorLink.token}`}
              />
              : null
          }
          onClose={() => setShowCreatorSpectatorLink(false)}
          onConfirm={async () => {
            if (event.spectatorLink != null) {
              return setShowCreatorSpectatorLink(false);
            }

            await createSpectatorLinkMutation.call({
              eventUUID: event.uuid,
            });
          }}
        />
        : null}
      {event.videoMeeting != null &&
        !isSolo &&
        event.type !== EventTypeEnum.busy ? (
        <>
          <div>
            <Row style={{ alignItems: "stretch" }}>
              <JoinHiylloMeetButton onClick={onClickJoinMeeting}>
                <VideoMeetingIcon width={20} color="white" />
                Join Hiyllo Meet
              </JoinHiylloMeetButton>
              <CopyableLinkBox
                label="Public Meeting Join Link (Anyone with the link can participate)"
                link={`${getRootURL()}/meet/${event.uuid}/${event.videoMeeting.meetingPassword}`}
              />
            </Row>
            <div style={{ height: 10 }} />
            {event.spectatorLink != null ?
              <div style={{ width: 520 }}>
                <CopyableLinkBox
                  label="Public Spectator Link (Anyone with the link can spectate)"
                  link={`${getRootURL()}/m/s/${event.spectatorLink.token}`}
                />
              </div>
              :
              <Row>
                <Button
                  onClick={() => {
                    setShowCreatorSpectatorLink(true);
                  }}
                  label={<>
                    <FontAwesomeIcon icon={faScreenUsers} />
                    Creator Spectator Link
                  </>}
                  variant={ButtonVariant.narrow}
                  isSecondary
                />
              </Row>
            }
          </div>
        </>
      ) : null}
    </>
  );
});
