import React from "react";
import { LeftSidebar } from "../../components/left-sidebar";
import { Sidebar } from "@hiyllo/ux/tokyo";
import { useNavigateTo } from "@hiyllo/omni-router";
import { Features } from "@hiyllo/omni-common/src/types/navigation/features";
import { MainContent } from "../../components/main-content";
import { seamlessClient } from "../../../../seamless-client";
import { type MailEDataParamsType } from "@hiyllo/omni-common/src/types/navigation/edata";
import {
  useConfig,
  useConfigQuery,
} from "../../../../platform/config/config-context";
import { Typography } from "@hiyllo/ux/typography";
import { useNavigate, useSelf } from "@hiyllo/omni-continuity/main";
import { styled } from "@hiyllo/ux/styled";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { Input } from "@hiyllo/ux/input";
import { AnimatePresence, motion } from "framer-motion";

import * as SetEmailDomainBP from "../../../../blueprints/mail/set-email-domain";
import * as CheckEmailDomainBP from "../../../../blueprints/mail/check-email-domain";
import { type MoopsyError } from "@moopsyjs/core";
import { Button } from "@hiyllo/ux/button";
import { AnimateChangeInHeight } from "@hiyllo/ux/animation";
import { ComposeView } from "../../../mail/views/compose-view";
import { LoadingSpinnerFullView } from "../../../../platform/loading/spinner-loading-full";
import { LSButton } from "../../components/ls-button";
import {
  faEnvelope,
  faEnvelopeOpen,
  faInbox,
  faInboxOut,
  faMailbox,
  faPaperPlane,
  faPencil,
  faSearch,
  faShield,
} from "@fortawesome/pro-light-svg-icons";
import { MailListView } from "../../../mail/views/mail-list-view";
import { BuiltInFolderEnum } from "@hiyllo/omni-common/src/types/mail/organization/builtin-folders";
import { MailThreadView } from "../../../mail/views/thread-view";
import { LSText } from "../../components/ls-text";
import { getRootURL } from "../../../../platform/environment/get-root-url";
import { useShowDialog } from "@hiyllo/ux/dialogs";
import { MailboxesView } from "../../../mail/views/mailboxes-view";
import { TabDetails } from "../../tabbing/tabs-provider";
import { useTheme } from "@hiyllo/ux/theme";

const SlideOverFromRight = React.memo(function SlideOverFromRight(props: React.PropsWithChildren): JSX.Element {
  const $theme = useTheme();

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{
        type: 'keyframes',
        ease: 'easeInOut',
      }}
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 5,
        width: "100%",
        height: "100%",
        background: $theme.background1
      }}
    >
      {props.children}
    </motion.div>
  );
});

const FlexRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 16,
});

const CodeBlock = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: 16,
  alignItems: "center",
  background: $theme.background2,
  padding: 8,
  paddingRight: 16,
  borderRadius: 8,
  width: 600,
}));

const RecordType = styled("div", ({ $theme }) => ({
  background: $theme.background1,
  padding: 8,
  borderRadius: 4,
  userSelect: "none",
}));

const RecordValueInput = styled("input", ({
  fontFamily: "monospace",
  userSelect: "all",
  display: "block",
  background: "none",
  border: "none",
  width: "100%",
  paddingLeft: 0,
}));

const ErrorText = styled("div", ({
  color: "red",
}));

const DNSRecord = React.memo(function DNSRecord({
  type,
  host,
  value,
  priority,
}: {
  type: string;
  host: string;
  value: string;
  priority?: string | number;
}): JSX.Element {
  return (
    <CodeBlock>
      <RecordType>{type}</RecordType>
      <div style={{ width: 120, flexShrink: 0 }}>
        <div style={{ fontSize: 10 }}>Host</div>
        <RecordValueInput
          onFocus={(evt: React.FocusEvent<HTMLInputElement>) => {
            setTimeout(() => {
              evt.target.select();
            });
          }}
          value={host}
        />
      </div>
      {priority != null ? (
        <div style={{ width: 40, flexShrink: 0 }}>
          <div style={{ fontSize: 10 }}>Priority</div>
          <RecordValueInput
            onFocus={(evt: React.FocusEvent<HTMLInputElement>) => {
              setTimeout(() => {
                evt.target.select();
              });
            }}
            value={priority}
          />
        </div>
      ) : null}
      <div style={{ width: 0, flexGrow: 1 }}>
        <div style={{ fontSize: 10 }}>Value</div>
        <RecordValueInput
          onFocus={(evt: React.FocusEvent<HTMLInputElement>) => {
            setTimeout(() => {
              evt.target.select();
            });
          }}
          value={value}
        />
      </div>
    </CodeBlock>
  );
});

export const EnableMailForm = React.memo(function EnableMailForm(): JSX.Element {
  const [domain, setDomain] = React.useState<string>("");
  const config = useConfig();
  const queryConfig = useConfigQuery();
  const setEmailDomainMutation =
    seamlessClient.useMutation<SetEmailDomainBP.Plug>(
      SetEmailDomainBP,
      { querySideEffects: [queryConfig] },
    );
  const checkEmailDomainMutation =
    seamlessClient.useMutation<CheckEmailDomainBP.Plug>(
      CheckEmailDomainBP,
      { querySideEffects: [queryConfig] },
    );

  const showDialog = useShowDialog();

  const submitSetEmailDomain = React.useCallback(() => {
    const trimmedDomain = domain.trim();

    if (trimmedDomain === "") {
      return;
    }

    if (!trimmedDomain.includes(".")) {
      return showDialog({
        title: "Invalid Domain",
        message: "Please enter a valid domain.",
        onConfirm: () => {
          //
        },
      });
    }

    if (trimmedDomain.includes(" ")) {
      return showDialog({
        title: "Invalid Domain",
        message: "Your domain should not contain spaces",
        onConfirm: () => {
          //
        },
      });
    }

    const domainRegexp = /^[a-zA-Z0-9.-]+$/;

    if (!domainRegexp.test(trimmedDomain)) {
      return showDialog({
        title: "Invalid Domain",
        message: "Please check your domain is valid",
        onConfirm: () => {
          //
        },
      });
    }

    void setEmailDomainMutation.call({
      domain: trimmedDomain,
    });
  }, [domain, setEmailDomainMutation]);

  const [domainCheckError, setDomainCheckError] = React.useState<string | null>(
    null,
  );
  const submitCheckEmailDomain = React.useCallback(() => {
    void checkEmailDomainMutation.call(null).catch((e) => {
      setDomainCheckError((e as MoopsyError).description);
    });
  }, [checkEmailDomainMutation]);

  if (config.emailDomain != null) {
    return (
      <>
        <Typography.Paragraph>
          To setup your domain, add the following records
        </Typography.Paragraph>
        <DNSRecord
          type="MX"
          host="@"
          value="smtp-kirk.hiyllo.network"
          priority={10}
        />
        <DNSRecord
          type="MX"
          host="@"
          value="smtp-picard.hiyllo.network"
          priority={20}
        />
        <DNSRecord
          type="MX"
          host="@"
          value="smtp-sisko.hiyllo.network"
          priority={30}
        />
        <DNSRecord
          type="MX"
          host="@"
          value="smtp-janeway.hiyllo.network"
          priority={40}
        />
        <DNSRecord
          type="TXT"
          host="@"
          value="v=spf1 include:spf.hiyllo.network -all"
        />
        <DNSRecord
          type="TXT"
          host="@"
          value={"hyloml=" + getRootURL().slice(8)}
        />
        <DNSRecord
          type="TXT"
          host="hylo._domainkey"
          value={
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            "v=DKIM1; k=rsa; p=" + (config.emailDomainDKIMRecord ?? "")
          }
        />
        <Typography.Label>
          If your domain provider does not let you enter {'"@"'} as the host,
          leave the field blank
        </Typography.Label>
        <Typography.Label>
          Hiyllo has your back. If you need help email support@hiyllo.io
        </Typography.Label>
        <div style={{ flexShrink: 0 }}>
          <AnimateChangeInHeight>
            {domainCheckError != null ? (
              <ErrorText>
                {domainCheckError}. Sometimes records can take up to 24 hours to
                propagate. Check back here later.
              </ErrorText>
            ) : (
              <div />
            )}
          </AnimateChangeInHeight>
        </div>
        <FlexRow>
          <Button
            label="Verify Records"
            onClick={submitCheckEmailDomain}
            isLoading={checkEmailDomainMutation.isLoading}
          />
        </FlexRow>
      </>
    );
  }

  return (
    <>
      <Typography.Paragraph>
        Hiyllo Mail is included with your Hiyllo Work subscription. Enter your
        email domain (the part after the @) to get started
      </Typography.Paragraph>

      <FlexRow>
        <div style={{ width: 256 }}>
          <Input
            placeholder="example.com"
            fullWidth
            value={domain}
            onChangeValue={setDomain}
          />
        </div>
        <CircleButton size={32} onClick={submitSetEmailDomain} />
      </FlexRow>
    </>
  );
});

export const MailFeature = (props: {
  params: MailEDataParamsType;
}): JSX.Element => {
  const config = useConfig();
  const configQuery = useConfigQuery();
  const isAdmin = useSelf().isAdmin;
  const compose = useNavigateTo({
    feature: Features.mail,
    params: {
      view: "compose",
    },
  });
  const inbox = useNavigateTo({
    feature: Features.mail,
    params: {
      view: "mail",
      folder: BuiltInFolderEnum.inbox,
    },
  });
  const sent = useNavigateTo({
    feature: Features.mail,
    params: {
      view: "mail",
      folder: BuiltInFolderEnum.sent,
    },
  });
  const drafts = useNavigateTo({
    feature: Features.mail,
    params: {
      view: "mail",
      folder: BuiltInFolderEnum.drafts,
    },
  });
  const outbox = useNavigateTo({
    feature: Features.mail,
    params: {
      view: "mail",
      folder: BuiltInFolderEnum.outbox,
    },
  });
  const allMail = useNavigateTo({
    feature: Features.mail,
    params: {
      view: "mail",
      folder: "all",
    },
  });
  const spam = useNavigateTo({
    feature: Features.mail,
    params: {
      view: "mail",
      folder: BuiltInFolderEnum.spam,
    },
  });
  const mailboxes = useNavigateTo({
    feature: Features.mail,
    params: {
      view: "mailboxes",
      mailboxUUID: null,
    },
  });

  const [savedSearch, setSavedSearch] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (props.params.view === 'search') {
      setSavedSearch(props.params.query);
    }
  }, [props.params]);

  const navigate = useNavigate();

  const self = useSelf();
  const folderRef = React.useRef<BuiltInFolderEnum | "all">('folder' in props.params ? props.params.folder : BuiltInFolderEnum.inbox);
  if ('folder' in props.params) {
    folderRef.current = props.params.folder;
  }

  if (
    configQuery == null ||
    configQuery.isLoading ||
    config.kind !== "private"
  ) {
    return (
      <>
        <LeftSidebar>
          <Sidebar.Header label="Mail" />
        </LeftSidebar>
        <MainContent noPadding>
          <LoadingSpinnerFullView />
        </MainContent>
      </>
    );
  }

  if (config.emailDomain == null || config.emailDomainVerified !== true) {
    return (
      <>
        <LeftSidebar>
          <Sidebar.Header label="Mail" />
        </LeftSidebar>
        <MainContent noPadding>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // alignItems: "center",
              gap: 16,
              padding: 64,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 16,
                flexShrink: 0,
                overflow: "auto",
              }}
            >
              {isAdmin ? (
                <>
                  <Typography.Header>Hiyllo Mail</Typography.Header>
                  <EnableMailForm />
                </>
              ) : (
                <>
                  <Typography.Header>Hiyllo Mail</Typography.Header>
                  <Typography.Paragraph>
                    Hiyllo Mail hasn&apos;t been set up for your organization
                    yet. Your admin can set it up here.
                  </Typography.Paragraph>
                </>
              )}
            </div>
          </div>
        </MainContent>
      </>
    );
  }

  return (
    <TabDetails icon={faEnvelope} label="Mail">
      <LeftSidebar>
        <Sidebar.Header
          label="Mail"
          actionButtonLabel={"Compose"}
          actionButtonOnClick={compose}
        />
        <LSText
          label={`${self.username ?? ""}@${config.emailDomain}`}
          icon={null}
        />
        <AnimateChangeInHeight>
          {props.params.view === 'thread' && savedSearch != null ?
            <LSButton
              icon={{ fa: faSearch }}
              isActive={false}
              label={savedSearch}
              onClick={() => {
                navigate({
                  feature: Features.mail,
                  params: {
                    view: 'search',
                    query: savedSearch,
                  }
                });
              }}
            />
            : <div />}
        </AnimateChangeInHeight>
        <LSButton
          icon={{ fa: faInbox }}
          isActive={
            props.params.view === "mail" &&
            props.params.folder === BuiltInFolderEnum.inbox
          }
          label="Inbox"
          onClick={inbox}
        />
        <LSButton
          icon={{ fa: faPaperPlane }}
          isActive={
            props.params.view === "mail" &&
            props.params.folder === BuiltInFolderEnum.sent
          }
          label="Sent"
          onClick={sent}
        />
        <LSButton
          icon={{ fa: faInboxOut }}
          isActive={
            props.params.view === "mail" &&
            props.params.folder === BuiltInFolderEnum.outbox
          }
          label="Outbox"
          onClick={outbox}
        />
        <LSButton
          icon={{ fa: faPencil }}
          isActive={
            props.params.view === "mail" &&
            props.params.folder === BuiltInFolderEnum.drafts
          }
          label="Drafts"
          onClick={drafts}
        />
        <LSButton
          icon={{ fa: faEnvelopeOpen }}
          isActive={
            props.params.view === "mail" && props.params.folder === "all"
          }
          label="All Mail"
          onClick={allMail}
        />
        <LSButton
          icon={{ fa: faShield }}
          isActive={
            props.params.view === "mail" && props.params.folder === "spam"
          }
          label="Spam"
          onClick={spam}
        />
        <div style={{ height: 20 }} />
        <LSButton
          icon={{ fa: faMailbox }}
          isActive={props.params.view === "mailboxes"}
          label="Mailboxes"
          onClick={mailboxes}
        />
      </LeftSidebar>
      <MainContent noPadding>
        <div style={{ height: "100%", width: "100%", position: "relative" }}>
          <AnimatePresence>
            <MailListView folder={folderRef.current} search={props.params.view === 'search' ? props.params.query : ""} />
            {props.params.view === "compose" ? (
              <SlideOverFromRight key="compose">
                <ComposeView />
              </SlideOverFromRight>
            ) : props.params.view === "mailboxes" ? (
              <SlideOverFromRight key="mailboxes">
                <MailboxesView />
              </SlideOverFromRight>
            ) : props.params.view === 'thread' ? (
              <SlideOverFromRight key="thread">
                <MailThreadView threadUUID={props.params.threadUUID} />
              </SlideOverFromRight>
            ) : null}
          </AnimatePresence>
        </div>
      </MainContent>
    </TabDetails>
  );
};

export default MailFeature;

